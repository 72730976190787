import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Grid,
  IconButton,
  Paper,
  Switch,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { selectAllTest, selectTestApi, testCreateThunk, testListThunk, testUpdateThunk } from "store/reducers/test.reducer";
import dateUtil from "util/date.util";
import { CremaTheme } from "@cremaMods/types/AppContextPropsType";
import BasicTable, { IRow } from "components/Tables/BasicTable";
import { AppGridContainer } from "@crema";
import BasicTableHead from "components/Tables/BasicTable/BasicTableHead";
import BasicTableToolbar from "components/Tables/BasicTable/BasicTableToolbar"
import AddIcon from '@mui/icons-material/Add';
import PopOverInput from "components/PopOverInput";
import TestAddDialog from "./TestAddDialog";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import DeleteWarningDialog from "components/DeleteWarning";
import { setWeek } from "date-fns";
import { unwrapResult } from "@reduxjs/toolkit";
import { resetQuestionState } from "store/reducers/question.reducer";

const headCells: BasicTableHeadCellType[] = [
  { id: 'pos', label: '', align: 'left' },
  { id: 'id', label: 'Id', align: 'left' },
  { id: 'name', label: 'Name', align: 'left' },
  { id: 'noOfQns', label: 'No Of Questions', align: 'left' },
  { id: 'isActive', label: 'Is Active', align: 'left' },
  { id: 'createdAt', label: 'Created At', align: 'left' },
]

const getRows = (
  tests: TestResponseType[],
  theme: CremaTheme,
  handleSelect: (testId: number) => void,
  handlePosChange: (id: number, data: Partial<TestCreateType>) => void,
  handleActivation:(id: number, isActive: boolean) => void,
): IRow[] => {
  return tests?.map((test, index) => ({
    id: test.id,
    values: [
      {
        value: (
          <Box display='flex' flexDirection='column' alignItems={'center'}>
            <IconButton
              disabled={index === 0}
              sx={{ p: 0 }}
              onClick={() => {
                handlePosChange(test.id, { position: index })
              }} >
              <KeyboardArrowUp />
            </IconButton>
            <Typography sx={{
              fontSize: '12px',
              fontWeight: 500
            }}>{index + 1}</Typography>
            <IconButton
              disabled={index + 1 === tests.length}
              sx={{ p: 0 }}
              onClick={() => { handlePosChange(test.id, { position: index + 2 }) }}>
              <KeyboardArrowDown />
            </IconButton>
          </Box>
        ),
        tableCell: { component: 'th', scope: 'row' }
      },
      {
        value: (<Box sx={{
          color: theme.palette.primary.main,
          cursor: 'pointer',
          textDecoration: 'none',
          '&:hover': {
            color: theme.palette.secondary.main
          }
        }} onClick={() => handleSelect(test.id)}>{`#${test.id}`}</Box>),
        tableCell: { component: 'th', scope: 'row' }
      },
      { value: test.name },
      { value: test.questionCount },
      { value:  <Switch onChange={(e) => {
      handleActivation(test.id,e.target.checked)
      }} checked={test.isActive}/> },
      { value: dateUtil.isoDateToFormattedDateTime(test.createdAt) },
    ]
  }))
}

export default function TestList() {
  // const ref = useRef<HTMLElement>(null)
  const dispatch = useAppDispatch()
  const tests = useAppSelector(selectAllTest)
  const api = useAppSelector(selectTestApi)
  const [warningOpen, setWarningOpen] = useState<{id?:number,open:boolean,isActive?:boolean}>({open:false})
  const navigate = useNavigate()
  const theme = useTheme<CremaTheme>()

  useEffect(() => {
    dispatch(testListThunk())
  }, [])

  const handleActivation = useCallback((id:number,isActive:boolean) => {
    setWarningOpen({open:true,id,isActive})
  } ,[])
  const handleSelect = useCallback(
    (testId: number) => {
      dispatch(resetQuestionState())
      navigate(`view/${testId}`)
    },
    [navigate],
  )
  const handlePosChange = useCallback((id: number, data: Partial<TestCreateType>) => {
    dispatch(testUpdateThunk({ id, data }))
  }, [])
  const rows = useMemo(() => getRows(tests, theme, handleSelect, handlePosChange,handleActivation), [tests, theme, handleSelect, handlePosChange,handleActivation])
  const [dialogOpen, setDialogOpen] = useState<{ open: boolean }>({ open: false })
  return (
    <>
      <AppGridContainer>
        <Grid item xs={12}>
          <Paper>
            <BasicTable
              api={api}
              tableHead={(
                <BasicTableHead headCell={headCells} />
              )}
              rows={rows}

              toolbar={(
                <BasicTableToolbar
                  title={"Tests"}
                  action={(
                    <Tooltip title="Add Test">
                      <IconButton aria-label="filter list" onClick={() => { setDialogOpen({ open: true }) }}>
                        <AddIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                />
              )}
            />
          </Paper>
        </Grid>
      </AppGridContainer>
      {
        <TestAddDialog
          open={dialogOpen.open}
          handleClose={() => setDialogOpen({ open: false })}
        />
      }
      {
                <DeleteWarningDialog
                title={`Are you sure you want to ${warningOpen.isActive ? 'Acivate' : "Deactivate"}?`}
                buttonText="Submit"
                subTitle=""
                    deleteDialogOpen={warningOpen.open}
                    handlerCloseDialog={(canDelete) => {
                        
                        if (canDelete){
                          if(warningOpen.id )
                          dispatch(testUpdateThunk({ id:warningOpen.id, data:{isActive:warningOpen.isActive ?? false} }))
                        .then(unwrapResult)
                        .then(() => {
                          setWarningOpen({open:false})
                        })
                        }else{
                          setWarningOpen({open:false})

                        }
                    }}
                />
            }
    </>
  );
}
