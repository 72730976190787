import QuestionsList from "containers/Authorized/Dashboard/tests/QuestionsList";
import TestList from "containers/Authorized/Dashboard/tests/TestList";
import Tests from "containers/Authorized/Dashboard/tests/Tests";
import { DetailedRouteConfig } from "routes/routes.typing";
const routes : DetailedRouteConfig = {
   
    path: '/dashboard/tests',
    element: <Tests/>,
    children: [
        {
            path: '/dashboard/tests',
            element: <TestList/>,   
        },
        {
            path: '/dashboard/tests/view/:tId',
            element: <QuestionsList/>,   
        }
    ]
}

export default routes;