import { TextField, TextFieldProps } from '@mui/material'
import { useField } from 'formik'
import React from 'react'

type FormikTextFieldProps = Omit<TextFieldProps, 'name'> & {name: string}

const FormikTextField: React.FC<FormikTextFieldProps> = (props) => {
    const [field, meta] = useField(props.name)
    const errorText = meta.error && meta.touched ? meta.error : '';
    return (
        <TextField
            {...field}
            {...props}
            helperText={errorText}
            error={!!errorText}
        />
    )
}

export default FormikTextField