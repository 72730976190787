import { apiInstance } from 'api/client'
import react from 'react'

const listTests = async () =>
    apiInstance.get<TestResponseType[]>('/main/q-admin/', { cvtResToCamel: true })

const getTest = async (testId: number) =>
    apiInstance.get<TestResponseGetType>(`/main/q-admin/${testId}/`, { cvtResToCamel: true })

const createTest = async (data: TestCreateType) =>
    apiInstance.post<TestResponseType>('/main/q-admin/', { ...data }, { cvtResToCamel: true, cvtReqBodyToSnake: true })

const updateTest = async (data:Partial<TestCreateType>, testId: number) =>
    apiInstance.patch<TestResponseType>(`/main/q-admin/${testId}/`, { ...data }, { cvtResToCamel: true, cvtReqBodyToSnake: true })

const deleteTest = async (testId: number) =>
    apiInstance.delete(`/main/q-admin/${testId}/`)

const autoCreate = async (testId: number,data: AutoCreateType ) =>
    apiInstance.post(`/main/q-admin/${testId}/add-questions/`, { ...data }, { cvtResToCamel: true, cvtReqBodyToSnake: true })

export const fileUploadApi = {
    create: async (file: File) => {
        const formData = new FormData()
        formData.append('file', file)
        return apiInstance.post<FileUploadResponseType>('/files-app/file/', formData, {
             headers: { 'Content-Type': 'multipart/form-data' } ,
             cvtResToCamel:true
            })
    }
}


const testsApi = {
    listTests,
    getTest,
    createTest,
    deleteTest,
    updateTest,
    autoCreate
}

export default testsApi