import axios, { AxiosRequestConfig, AxiosResponse } from "axios"
import { apiInstance} from "./client"
import camelCaseKeys from 'camelcase-keys'
import snakeCaseKeys from 'snakecase-keys'
import { getAuth } from "firebase/auth"

const requestAttachTokenInterceptor = async(config: AxiosRequestConfig) => {
    if(config.data && config.cvtReqBodyToSnake)
        config.data = snakeCaseKeys(config.data)
    if(config.params && config.cvtReqParamsToSnake)
        config.params = snakeCaseKeys(config.params, {deep: true})
        const jwt = await getAuth().currentUser?.getIdToken(true)
    if (jwt !== undefined) {
        config.headers = { ...config.headers, 'Authorization':  jwt }
    }
    return config
}

// const refreshTokenFn = async (): Promise<JWTResponseType | undefined> => {
//     try {
//         const response = await refreshInstance.post<JWTResponseType>('/auth/refresh')
//         return response.data
//     } catch (err: any) {
//         // if (axios.isAxiosError(err))  {
//         // } 
//         return undefined
//     }
// }

const responseSuccessInterceptor = async (response: AxiosResponse) => {
    if(response.config.cvtResToCamel){
        // response.data = camelCaseKeys(response.data, {deep: true, exclude: [/^.*_\d.*$/]})
        response.data = camelCaseKeys(response.data, {deep: true})
    }
    return response
}


const responseErrorInterceptor = async (err: any) => {
    if(axios.isAxiosError(err)){
        const config = err.config
        if(config !== undefined && err.response?.status === 401 && !config.alreadySent){
            config.alreadySent = true
                return axios(config)
        }    
    }
    return Promise.reject(err)
}


// refreshInstance.interceptors.request.use((config) => {
//     const refresh = store.getState().auth.jwt?.refresh
//     if (refresh !== undefined) {
//         config.headers = { ...config.headers, 'Authorization': `JWT ${refresh}` }
//     }
//     return config
// }, (error) => Promise.reject(error))


// identityInstance.interceptors.request.use(requestAttachTokenInterceptor, (error) => Promise.reject(error))
apiInstance.interceptors.request.use(requestAttachTokenInterceptor, (error) => Promise.reject(error))

// identityInstance.interceptors.response.use(responseSuccessInterceptor, responseErrorInterceptor)
apiInstance.interceptors.response.use(responseSuccessInterceptor, responseErrorInterceptor)