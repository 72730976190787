import * as React from 'react';
import {
    Formik,
    FormikHelpers,
    Form,
    FieldArray,

} from 'formik';
import * as yup from 'yup'
import { Box, Button, Grid, Link, Paper, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import clsx from 'clsx';
import LoginStyles from 'styles/login.module.scss'
import { useAppDispatch } from 'store/hooks';
import { loginThunk } from 'store/reducers/auth.reducer';
import FormikTextField from 'components/FormikTextField';
import FolderIcon from '@mui/icons-material/Folder';
import CloseIcon from '@mui/icons-material/Close';
import FormikCustomField from 'components/FormikChoiceField/FormikCustomField';
import FieldWrapper from 'components/Fields/FieldWrapper';
import FillTheBlankField, { TemplateType } from 'components/FillTheBlankFiled/FillTheBlankField';
import { questionsCreateThunk } from 'store/reducers/question.reducer';
import { unwrapResult } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';

export type ChoiceFormType = {
    choice: string,
    isAnswer: string,
    index: number
}

export type FormValuesType = {
    questionType: string
    question: string,
    videoUrl: string,
    choices: ChoiceFormType[],

}

type Props = {
    testId?: number,
    test?: TestResponseType
}

const AddQuestionForm: React.FC<Props> = ({ test, testId }) => {

    const [questionArray, setQuestionArray] = React.useState<TemplateType[]>([''])
    const [choices, setChoices] = React.useState<ChoiceFormType[]>([
        { choice: '', isAnswer: 'false', index: 0 }
    ])
    const [file, setFile] = React.useState<File | undefined>()
    const dispatch = useAppDispatch()
    const [apiError, setApiError] = React.useState<any>(undefined)

    const initialValues: FormValuesType = { 
        questionType: 'multiple_choice', 
        question: '', 
        videoUrl: '', 
        choices: [...choices] 
    };
    const handleSubmit = (async (values: FormValuesType, actions: FormikHelpers<FormValuesType>) => {
        setApiError(undefined)
        const questionString = questionArray.reduce((map: string, val: any) => {
            if (typeof val === 'string') {
                return map + val
            } else {
                return map + " [[~choice~]] "
            }
        }, "")
        const tempValues: any = values.questionType === 'multiple_choice' ? { ...values } : { ...values, question: questionString }
        Object.entries(tempValues).forEach(([key, value]) => {
            if (value === '') tempValues[key] = null
            if (key === 'choices') {
                const selectedChoiceArr = [...questionArray].filter(e => typeof e !== 'string')
                tempValues['choices'] = tempValues['choices'].map((item: ChoiceFormType) => {
                    const find: any = selectedChoiceArr.find(e => (typeof e !== 'string' && e.value === item.choice))
                    return find ? { choice: find.value, isAnswer: true, choicePosition: find.index } : { choice: item.choice, isAnswer: item.isAnswer === 'true' }
                })
            }
        })

        if (testId && test) {
            dispatch(questionsCreateThunk({ data: { ...tempValues, tests: [{ test: test.url, isTrial: false }] } }))
                .then(unwrapResult)
                .then(() => {
                    toast.success('Question Successfully Created')
                    actions.resetForm()
                    // setSnackBarStatus({ open: true, message: 'Question Successfully Created', status: 'success' })
                }).catch((error) => {
                    setApiError(error)
                    toast.error('Error While creating question')
                    // setSnackBarStatus({ open: true, message: 'Error While creating question', status: 'fail' })

                })
                .finally(() => {
                    actions.setSubmitting(false)
                })
        }

    })


    const ChoiceFormTypeSchema = yup.object().shape({
        choice: yup.string().required(),
        isAnswer: yup.string().required(),
        index: yup.number()
    });
    
    const validationSchema = yup.object().shape({
        // questionType: yup.string().required(),
        // // question: yup.string().required(),
        // videoUrl: yup.string().url(),
        // choices: yup.array().of(ChoiceFormTypeSchema).required()
    });
    

    return (
        <Box>
            <Box>
                <Formik
                    initialValues={initialValues}
                    validateOnChange={true}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ values, setSubmitting, dirty, isSubmitting, setFieldValue,errors }) => (
                        <Form className={clsx(LoginStyles.loginForm)}>
                            <Box display={'flex'} flexDirection={'column'} gap={3}>
                                <FieldWrapper title={"Update Type"}>
                                    <ToggleButtonGroup
                                        fullWidth
                                        size='small'
                                        color="primary"
                                        value={values.questionType}
                                        exclusive
                                        onChange={(_, val) => setFieldValue('questionType', val)}
                                    >
                                        <ToggleButton value="multiple_choice">Multiple Choice</ToggleButton>
                                        <ToggleButton value="fill_in_the_blank">Fill In The Blanks</ToggleButton>
                                    </ToggleButtonGroup>
                                </FieldWrapper>

                                {
                                    values.questionType === 'multiple_choice' ? (
                                        <FormikCustomField
                                            name='question'
                                            label="Question"
                                            placeholder='Question'
                                            bgColor='#F8F0F8'
                                            onChange={(val) => { setFieldValue('question', val) }}
                                            value={values.question}
                                        />
                                    ) : (
                                        <FillTheBlankField
                                            questionArray={questionArray}
                                            setQuestionArray={setQuestionArray}
                                            choices={values.choices}
                                            label="Question"
                                            placeholder='Question'
                                            bgColor='#F8F0F8'
                                            onChange={(val) => { setFieldValue('question', val) }}
                                            value={values.question}
                                        />
                                    )
                                }


                                <FormikCustomField
                                    label="Video url"
                                    name='videoUrl'
                                    placeholder='Video Url'
                                    bgColor='#F8F0F8'
                                    onChange={(val) => { setFieldValue('videoUrl', val) }}
                                    value={values.videoUrl}
                                />
                                <FieldArray
                                    name="friends"
                                    render={arrayHelpers => (
                                        <Grid container columnSpacing={6} rowSpacing={4}>

                                            {values.choices.map((item, index) => (
                                                <Grid item
                                                    key={index}
                                                    xs={12} md={6}
                                                >
                                                    <FormikCustomField
                                                        onClose={() => {
                                                            setFieldValue('choices', [...values.choices].filter(e => e.index !== index))
                                                        }}
                                                        value={item.choice}
                                                        isChoice
                                                        isAnswer={item.isAnswer === 'true'}
                                                        setIsAnswer={values.questionType === 'multiple_choice' ? () => {
                                                            setFieldValue(`choices.${index}`, { ...item, isAnswer: item.isAnswer === 'true' ? 'false' : 'true' })
                                                        } : undefined}
                                                        onChange={(val) => {
                                                            setFieldValue(`choices.${index}`, { ...item, choice: val })
                                                        }}
                                                        label={`Choice ${index + 1}`}
                                                        name={`choices.${index}`}
                                                        placeholder={`Choice ${index + 1}`}
                                                        bgColor='#EDF8FF'
                                                    />
                                                </Grid>
                                            ))}
                                            <Grid item xs={12} md={6} alignSelf={'flex-end'}
                                            >
                                                <Button
                                                    onClick={() => setFieldValue('choices', [...values.choices, { choice: '', isAnswer: 'false', index: values.choices.length }])}
                                                    variant='outlined'
                                                    sx={{
                                                        width: 'fit-content',
                                                        paddingX: '30px'
                                                    }}
                                                >Add Choice</Button>
                                            </Grid>


                                        </Grid>
                                    )}
                                />


                                <Box display={'flex'} justifyContent={'flex-start'} gap={6}>


                                    {file && <Box sx={{
                                        border: 'solid 1px black',
                                        padding: '5px ',
                                        borderRadius: '5px',
                                        position: 'relative'
                                    }}>
                                        <Box
                                            onClick={() => {
                                                setFile(undefined)
                                            }}
                                            sx={{
                                                position: 'absolute',
                                                top: '0',
                                                right: '0',
                                                cursor: 'pointer'
                                            }}>
                                            <CloseIcon />
                                        </Box>
                                        <img style={{ width: '150px', height: '75px', objectFit: 'contain' }} src={URL.createObjectURL(file)} alt="" />

                                    </Box>}
                                </Box>

                               

                                <Typography color={'red'}>{apiError && apiError?.question && apiError?.question[0] && 'Question: ' +  apiError?.question[0]}</Typography>
                                <Typography color={'red'}>{apiError && apiError?.choices && apiError?.choices[0] && apiError?.choices[0].choice && apiError?.choices[0].choice[0] &&  'Choice: ' +   apiError?.choices[0].choice[0]}</Typography>
                                <Typography color={'red'}>{apiError && apiError?.detail &&    apiError?.detail}</Typography>

                                <Box display={'flex'} justifyContent={'end'}>
                                    <Button
                                        disabled={isSubmitting || !dirty}
                                        type='submit'
                                        variant='contained'
                                        sx={{
                                            width: 'fit-content',
                                            paddingX: '30px'
                                        }}
                                    >Save</Button>
                                </Box>


                            </Box>
                        </Form>
                    )}
                </Formik>
            </Box>
        </Box>

    )
}

export default AddQuestionForm


