import { Box, Typography } from "@mui/material"
import React from "react"

const FieldWrapper: React.FC<React.PropsWithChildren<{ title?: React.ReactNode }>> = ({ title, children }) => {
    return (
      <>
        <Box >
          {title && (
            <Typography p={0} ml={1} fontWeight={500} fontSize={'12px'}>{title}</Typography>
          )}
        </Box>
        {children}
      </>
    )
  }

  export default FieldWrapper