
import { Box, Button, Card, CardContent, CardHeader, Divider, MenuItem, ToggleButton, ToggleButtonGroup, Typography, lighten } from '@mui/material'
import { unwrapResult } from '@reduxjs/toolkit'
import FormikTextField from 'components/FormikTextField'
import { Formik } from 'formik'
import React from 'react'
import toast from 'react-hot-toast'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { createConfigThunk, deleteConfigThunk, selectAllConfig } from 'store/reducers/dynamicConfig.reducer'
import { toCamelCase } from 'util/stringUtils'
import * as yup from 'yup'

type Props = {
    val?: SeruyConfigResponseType,
    isNew?: boolean
}

const configKeyOptions = [
    'stripe_price',
    'offer_stripe_price',
    'apple_iap_id',
    'offer_apple_iap_id',
    'offer_enabled'
]

const ConfigForm: React.FC<Props> = ({ val, isNew }) => {
    const configs = useAppSelector(selectAllConfig)

    const dispatch = useAppDispatch()

    const validationSchema = yup.object({
        configKey: yup.string().required(),
        configValue: yup.string().required()
    })

    const initialValues: SeruyConfigResponseType = val ? val : {
        configKey: "",
        configValue: ""
    }
    return (

        <Box>

            {/* <Divider sx={{ my: 4 }} /> */}
            <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={(values, helpers) => {
                    dispatch(createConfigThunk({ ...values }))
                        .then(unwrapResult)
                        .then(() => {
                            toast.success("Config Created Successfully")
                            helpers.resetForm()
                        })
                        .catch(() => {
                            toast.error("Something went wrong")
                        })
                        .finally(() => {
                            helpers.setSubmitting(false)
                        })
                    // if(values.configKey === 'offer_enebled'){
                    //     dispatch(createConfigThunk({...values,configValue:values.configValue === 'true' ? true : false}))
                    //     .then(unwrapResult)
                    //     .then(() => {
                    //         toast.success("Config Created Successfully")
                    //         helpers.resetForm()
                    //     })
                    //     .catch(() => {
                    //         toast.error("Somethinh went wrong")
                    //     })
                    //     .finally(() => {
                    //         helpers.setSubmitting(false)
                    //     })
                    // } else{
                    //     dispatch(createConfigThunk({...values}))
                    //     .then(unwrapResult)
                    //     .then(() => {
                    //         toast.success("Config Created Successfully")
                    //         helpers.resetForm()
                    //     })
                    //     .catch(() => {
                    //         toast.error("Somethinh went wrong")
                    //     })
                    //     .finally(() => {
                    //         helpers.setSubmitting(false)
                    //     })
                    // }


                }}
                validationSchema={validationSchema}
            >{
                    ({ values, setFieldValue, isSubmitting, dirty, submitForm }) => (
                        <>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 8, alignItems: 'center' }}>
                                {/* <Box sx={{
                                    backgroundColor: (theme) => lighten(theme.palette.primary.main, 0.9),
                                    height: '50px',
                                    borderRadius: '4px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }} minWidth={150} mt={2} fontWeight={500}>
                                    {toCamelCase(val.configKey)}
                                </Box> */}

                                <FormikTextField disabled={!isNew} name='configKey' label={"Config Key"} fullWidth select={isNew} >
                                    <MenuItem value={''}>{'Select'}</MenuItem>
                                    {
                                        configKeyOptions.filter(e => configs.findIndex(config => config.configKey === e) === -1).map((e, index) => <MenuItem key={index} value={e}>{e}</MenuItem>)
                                    }
                                </FormikTextField>
                                <FormikTextField name='configValue' label={"Config Value"} fullWidth />
                                <Button sx={{ my: 2 }} disabled={isSubmitting || !dirty} onClick={() => submitForm()} variant='contained' color='primary'>
                                    Save
                                </Button>
                                {val && <Button sx={{ my: 2 }} onClick={() => {
                                    dispatch(deleteConfigThunk(val?.configKey))
                                }} variant='outlined' color='error'>
                                    Delete
                                </Button>}

                            </Box>

                        </>
                    )
                }</Formik>
        </Box>
    )
}


export default ConfigForm