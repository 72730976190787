import { TableCell, TableHead, TableRow } from '@mui/material'
import React from 'react'

const BasicTableHead = (props:HeadCellType) => {
   const {headCell} = props
  return (
    <TableHead>
            <TableRow >
                {headCell.map((col)=>{
                    return(
                        <TableCell key={col.id} align={col.align ? col.align : 'center'}>{col.label}</TableCell>
                    )
                })}
            </TableRow>
          </TableHead>
  )
}

export default BasicTableHead