import { AxiosProgressEvent } from "axios"

export enum ApiStatusEnum {
   IDLE = 'idle',
   LOADING = 'loading',
   SUCCEEDED = 'succeeded',
   FAILED = 'failed'
}
export interface IApiState {
   status: ApiStatusEnum,
   error?: any
}

export type UploadProgessFnType = (progressEvent: AxiosProgressEvent) => void