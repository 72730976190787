import { Box, TableCell, TableCellProps } from '@mui/material'
import React from 'react'


const FullWidthTableCell : React.FC<TableCellProps> = props => {
    return (
        <TableCell {...props}>
            <Box 
                style={{
                        width: 'max-content', 
                        maxWidth: 150, 
                        display: 'inline-block', 
                        textAlign: props.align ?? 'left'
                    }}>
                {props.children}
            </Box>
        </TableCell>
    )
}

export default FullWidthTableCell