import { Close } from '@mui/icons-material'
import { Dialog, DialogContent, DialogProps, DialogTitle, IconButton, Typography } from '@mui/material'
import React, { PropsWithChildren } from 'react'

const DialogWithClose: React.FC<PropsWithChildren & DialogProps> = ({ children, ...props }) => {
    return (
        <Dialog {...props} >
            <DialogTitle display={'flex'} justifyContent={"space-between"} alignItems={'center'} >
                <Typography variant='h3'>{props.title}</Typography>
                {props.onClose && <IconButton onClick={(e) => props.onClose!(e, 'backdropClick')}>
                    <Close />
                </IconButton>}
            </DialogTitle>
            <DialogContent>
                {children}
            </DialogContent>
        </Dialog>
    )
}

export default DialogWithClose