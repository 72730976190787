import Default from "../../../@cremaMods/core/AppLayout/DefaultLayout";
import { NavStyle } from "@cremaMods/shared/constants/AppEnums";
import BitBucket from "../../../@cremaMods/core/AppLayout/BitBucket";
import Standard from "../../../@cremaMods/core/AppLayout/Standard";
import DrawerLayout from "../../../@cremaMods/core/AppLayout/DrawerLayout";
import MiniSidebar from "../../../@cremaMods/core/AppLayout/MiniSidebar";
import MiniSidebarToggle from "../../../@cremaMods/core/AppLayout/MiniSidebarToggle";
import HeaderUserLayout from "../../../@cremaMods/core/AppLayout/UserHeader";
import HeaderUserMiniLayout from "../../../@cremaMods/core/AppLayout/UserMiniHeader";
import HorDefault from "../../../@cremaMods/core/AppLayout/HorDefault";
import HorHeaderFixed from "../../../@cremaMods/core/AppLayout/HorHeaderFixed";
import HorDarkLayout from "../../../@cremaMods/core/AppLayout/HorDarkLayout";

const Layouts: {[key: string]:  React.FC<React.PropsWithChildren>}  = {
  [NavStyle.DEFAULT]: Default,
  [NavStyle.BIT_BUCKET]: BitBucket,
  [NavStyle.STANDARD]: Standard,
  [NavStyle.DRAWER]: DrawerLayout,
  [NavStyle.MINI]: MiniSidebar,
  [NavStyle.MINI_SIDEBAR_TOGGLE]: MiniSidebarToggle,
  [NavStyle.HEADER_USER]: HeaderUserLayout,
  [NavStyle.HEADER_USER_MINI]: HeaderUserMiniLayout,
  [NavStyle.H_DEFAULT]: HorDefault,
  [NavStyle.HOR_HEADER_FIXED]: HorHeaderFixed,
  [NavStyle.HOR_DARK_LAYOUT]: HorDarkLayout,
};
export default Layouts;
