
export const localStorageKeys = {
    // ACCESS_TOKEN: 'access',
    // REFRESH_TOKEN: 'refresh',
    // HOSPITAL_ID: 'hospitalId',
    JWT_TOKEN:'jwt',
    USER:'user'
}

export const servers = {
    IDENTITY_SERVER: '',
    API_SERVER: 'https://seru-api.equalon.uk'

    // API_SERVER: ' https://seru-api-test.equalon.uk/'
   
}

export const defaultDateFormat = 'dd/MM/yyyy'
export const defaultTimeFormat = 'hh:mm a'
export const defaultDateTimeFormat = 'do MMMM, yyyy hh:mm a'

export const apiDateFormat = 'yyyy-MM-dd'
export const apiTimeFormat = 'HH:mm:ss'

export const commonRegexPatterns = {
    phoneNo: /^\+?[\d\s]{8,20}$/,
    aadhaar: /^[\d]{12}$/,
    panNo: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/
}


export enum RejoinEnum{
    FRONT='front',
    REAR='rear'
}

export const dashboardInitPath = '/dashboard/home'