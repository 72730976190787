import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material'
import React from 'react'

type DeleteWarningDialogProps = {
    deleteDialogOpen:boolean,
    handlerCloseDialog:(canDelete:boolean)=>void,
    title?:string,
    subTitle?:string,
    buttonText?:string
}

const DeleteWarningDialog:React.FC<DeleteWarningDialogProps> = ({deleteDialogOpen,handlerCloseDialog,subTitle,title,buttonText}) => {
  return (
    <Dialog open={deleteDialogOpen}>
        <DialogTitle>{title ?? `Are you sure you want to delete?`}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {subTitle ?? "Warning: You cannot reverse this action!"}
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{padding:'0 12px 12px 0'}}>
          <Button size='small' variant="outlined" color="primary" onClick={() => handlerCloseDialog(false)}>
            <Typography variant='body2'>Cancel</Typography>
          </Button>
          <Button size='small' color={buttonText ? 'primary' : "secondary"} variant={buttonText ?  'contained':"outlined"} onClick={() => handlerCloseDialog(true)} autoFocus>
            <Typography  variant='body2'>{buttonText ?? "Delete"}</Typography>
          </Button>
        </DialogActions>
      </Dialog>
  )
}

export default DeleteWarningDialog