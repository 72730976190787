
import UserDetails from "containers/Authorized/Dashboard/users/UserDetails";
import Users from "containers/Authorized/Dashboard/users/Users";
import UsersTable from "containers/Authorized/Dashboard/users/UsersTable";
import { DetailedRouteConfig } from "routes/routes.typing";


const routes :DetailedRouteConfig ={
    path :'/dashboard/users',
    element : <Users/>,
    
    children: [
        {
            path: '/dashboard/users/:userId',
            element: <UserDetails/>
        },
        {
            path: '/dashboard/users',
            element: <UsersTable/>
        }
    ]
}
export default routes;