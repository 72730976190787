import Checkbox from '@mui/material/Checkbox';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import React from 'react';
import FullWidthTableCell from '../FullwidthTableCell';
import { PaginatedHeadCell, PaginatedOrder } from './types';

export interface TableSelectAllProps {
  selectAllHandler: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void,
  indeterminate: boolean,
  checked: boolean,
}

interface PaginatedTableHeadProps<T extends {}> {
  headCells: PaginatedHeadCell<T>[]
  orderBy: string,
  order: PaginatedOrder,
  sortHandler: (id: keyof T) => void,
  selectAllProps?: TableSelectAllProps
}

const PaginatedTableHead = <T extends {},>(props: React.PropsWithChildren<PaginatedTableHeadProps<T>>) => {
  const {headCells, orderBy, order, sortHandler, selectAllProps} = props

  return (
    <TableHead>
      <TableRow>
        {selectAllProps && (
          <FullWidthTableCell>
              <Checkbox
                color="primary"
                indeterminate={selectAllProps.indeterminate}
                checked={selectAllProps.checked}
                onChange={selectAllProps.selectAllHandler}
                inputProps={{
                  "aria-label": "select all desserts"
              }}/>
          </FullWidthTableCell>
        )}
        {headCells.map((headCell) => (
          <FullWidthTableCell
            key={headCell.id as string}
            align={headCell.align}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.hasSorting ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={() => sortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span
                    style={{
                      border: 0,
                      clip: 'rect(0 0 0 0)',
                      height: 1,
                      margin: -1,
                      overflow: 'hidden',
                      padding: 0,
                      position: 'absolute',
                      top: 20,
                      width: 1,
                  }}
                  >
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            ) : (<>{headCell.label}</>)
            }
          </FullWidthTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default PaginatedTableHead;