import React, { useEffect } from 'react'
import { Outlet } from 'react-router'
import { useAppDispatch } from 'store/hooks'
import {  getConfigThunk } from 'store/reducers/dynamicConfig.reducer'

const SeruConfig = () => {
    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(getConfigThunk())
    }, [dispatch])
    
    return <Outlet />
}

export default SeruConfig