import { Alert, Box, Button, Divider, Paper, Snackbar, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { selectTestAttemptsFilterParams, testAttemptListThunk } from 'store/reducers/testAttempt.reducer'
import { selectUserById, userSyncThunk } from 'store/reducers/user.reducer'
import AttemptsTable from '../attempts/AttemptsTable'
import 'firebase/auth';
import { unwrapResult } from '@reduxjs/toolkit'
import userApi from 'api/UserApi'

function isWithin180Days(dateString:string) {
  const date = new Date(dateString);
  const today = new Date();
  const diffTime = Math.abs(today.getTime() - date.getTime());
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays <= 180;
}

const UserDetails = () => {
  const { userId } = useParams()
  const dispatch = useAppDispatch()
  const filterArgs = useAppSelector(selectTestAttemptsFilterParams)
  const userDetails = useAppSelector(state => selectUserById(state, userId ?? ''))
  const [snackBarStatus, setSnackBarStatus] = useState<{ open: boolean, message?: string, status?: 'success' | 'fail' }>({ open: false })
  const [isPremiumUser, setIsPremiumUser] = useState<boolean>(false)

  useEffect(() => {

    dispatch(testAttemptListThunk())
  }, [dispatch, filterArgs])

  useEffect(() => {

    const fetchUsersInfo = async () => {
      // List of user UIDs
      const response = await userApi.getPaymentInfo(userId ?? "")
      if ( response.data?.results.length > 0 && response.data?.results[0].activated !== null && isWithin180Days(response.data?.results[0].activated)) {
        setIsPremiumUser(true)
      }
    }
    fetchUsersInfo();
  }, []);

  const navigate = useNavigate()

  return (
    <Box>
      <Button size='small' sx={{ marginBottom: '15px' }} variant='outlined' onClick={() => navigate('/dashboard/users')}>Back</Button>
      <Box>
        <Box component={Paper} mb={5}
          sx={{ backgroundColor: '#fff', padding: '30px 20px 20px 30px', borderRadius: '4px', width: { xs: '100%' } }}>
          <Box display={'flex'} justifyContent={'space-between'} mb={3}>
            <Typography variant='h4'>User Details</Typography>
            <Button onClick={() => {
              dispatch(userSyncThunk(userId ?? ""))
                .then(unwrapResult)
                .then(() => {
                  setSnackBarStatus({
                    open: true,
                    message: "Sync successful!",
                    status: 'success'
                  })
                }
                )
                .catch(() => {
                  setSnackBarStatus({
                    open: true,
                    message: "Sync failed!",
                    status: 'fail'
                  })
                })
            }} variant='outlined'>Sync</Button>
          </Box>
          <Divider />
          <Box mt={5} display='flex' justifyContent='space-between'>
            <Box flex={1} display='flex' flexDirection={'column'} gap={'10px'}>
              <Typography>Name</Typography>
              <Typography>Email</Typography>
              <Typography>Staff</Typography>
              <Typography>Premium User</Typography>
              <Typography>DOB</Typography>
              <Typography>Gender</Typography>
            </Box>
            <Box flex={1} display='flex' flexDirection={'column'} gap={'10px'}>
              <Typography fontWeight={600}> {userDetails?.user?.displayName ? userDetails?.user?.displayName : "---"} </Typography>
              <Typography fontWeight={600}>{userDetails?.user?.email ? userDetails?.user?.email :  "---"}</Typography>
              {/* <Typography fontWeight={600}>{userDetails?.phoneNumber ?? '---'}</Typography> */}
              <Typography fontWeight={600}>{userDetails?.user?.isStaff ? 'Yes' : 'No'}</Typography>
              <Typography fontWeight={600}>{isPremiumUser ? 'Yes' : 'No'}</Typography>
              <Typography fontWeight={600}>{userDetails?.dateOfBirth ? userDetails?.dateOfBirth : "---"}</Typography>
              <Typography fontWeight={600}>{userDetails?.gender ? userDetails?.gender : "---"}</Typography>
            </Box>
          </Box>
        </Box>
        <AttemptsTable userId={userId} />
      </Box>
      {
        snackBarStatus.open && <Snackbar open={snackBarStatus.open} autoHideDuration={6000} onClose={() => setSnackBarStatus({ open: false })}>
          <Alert
            severity={snackBarStatus.status === 'success' ? 'success' : 'error'}
            style={{ backgroundColor: snackBarStatus.status === 'success' ? 'green' : 'red', color: '#fff', width: '100%' }}
            onClose={() => setSnackBarStatus({ open: false })}
          >
            {snackBarStatus.message ?? ''}
          </Alert>
        </Snackbar>
      }
    </Box>
  )
}

export default UserDetails