import React from "react";
import Box from "@mui/material/Box";
import AppContentViewWrapper from "./AppContentViewWrapper";
import { SxProps } from "@mui/system";
import AppFooter from "@crema/core/AppLayout/components/AppFooter";

interface AppContentViewProps {
  sxStyle?: SxProps;
}

const AppContentView: React.FC<React.PropsWithChildren<AppContentViewProps>> = ({ sxStyle, children }) => {

  return (
    <AppContentViewWrapper>
      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          padding: { xs:'20px 10px', md: 7.5, xl: 12.5 },
          ...sxStyle,
        }}
        className="app-content"
      >
        {children}
      </Box>
      <AppFooter />
    </AppContentViewWrapper>
  );
};

export default AppContentView;
