import { useEffect, useMemo, useState } from 'react';
import { Box, Button, Dialog, IconButton, LinearProgress, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { excelImportThunk, questionsDeleteThunk, questionsListThunk, questionsUpdateThunk, QuestionWithPostionType, selectQuestionPagination, selectSortedQuestions } from 'store/reducers/question.reducer';
import { selectTestById, testDeleteThunk, testGetThunk, testListThunk } from 'store/reducers/test.reducer';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import { unwrapResult } from '@reduxjs/toolkit';
import DeleteWarningDialog from 'components/DeleteWarning';
import FolderIcon from '@mui/icons-material/Folder';
import CustomDropZone from 'components/CustomDropZone';
import AddQuestionForm from './AddQuestionForm';
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import { AutoFixHigh } from '@mui/icons-material';
import AutoCreateForm from './AutoCreate/AutoCreateForm';
import QuestionAccordion from './QuestionAccordion';
import toast from 'react-hot-toast';
import TestAddDialog from './TestAddDialog';




export type SeruDeleteDialogStatusType = { deleteItem?: 'question' | 'test', question?: QuestionWithPostionType, status: boolean }

const QuestionsList = () => {

    const { tId } = useParams()
    const testId = useMemo(() => {
        if (tId) return parseInt(tId)
    }, [tId])

    // const [pagination, setPagination] = useState(1)
    const [page, setPage] = useState(1)

    const test = useAppSelector(state => selectTestById(state, testId ?? ''))
    const questions = useAppSelector(state => selectSortedQuestions(state, test?.url ?? ""))

    const sortedQuestions = useMemo(() => {
        return questions.slice(((page * 20) - 20), page * 20)
    }, [page, questions])

    const [loading, setLoading] = useState(true)
    const dispatch = useAppDispatch()
    useEffect(() => {
        if (testId) { dispatch(testGetThunk(testId)) }
    }, [dispatch, testId])
    useEffect(() => {
        if (testId && test) {
            dispatch(questionsListThunk(test?.links.getQuestionList))
                .finally(() => {
                    setLoading(false)
                })
        }
    }, [dispatch, testId, test])
    const changePosition = (data: { question: QuestionWithPostionType, position: number,reload?:boolean }) => {
        if (testId && test) {
            dispatch(questionsUpdateThunk({
                data: { tests: data.question.tests.filter(e => e.test === test?.url).map(e => ({ ...e, position: data.position })) },
                questionId: data.question.id,
                testId: testId
            }))
            .then(unwrapResult)
            .then(() => {
                // if ( pagination?.current && data.reload) {
                //     setLoading(true)
                //     dispatch(questionsListThunk(pagination?.current))
                //     .then(unwrapResult)
                //     .then(() => {
                //         setLoading(false)
                //     })
                // }
            })
        }
    }
    const navigate = useNavigate()
    const [InputDialogOpen, setInputDialogOpen] = useState(false)
    const [deleteDialogOpen, setDeleteDialogOpen] = useState<SeruDeleteDialogStatusType>({ status: false })
    const [fileUploadOpen, setFileUploadOpen] = useState<{ status: boolean, question?: number }>({ status: false })
    const [excelImportError, setExcelImportError] = useState('')
    const [autoCreateDialogOpen, setAutoCreateDialogOpen] = useState(false)
    const pagination = useAppSelector(selectQuestionPagination)




    return (
        <>
            <Box sx={{ width: '100%' }}>
                <Box >
                    <Box mb={3} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Box display='flex' alignItems='center' gap='5px'>
                            <IconButton size='small' style={{ padding: 0 }} onClick={() => navigate('/dashboard/tests')}>
                                <ArrowBackIcon />
                            </IconButton>
                            <Typography variant='h3'>{test?.name}</Typography>
                            <IconButton size='small' onClick={() => {
                                setInputDialogOpen(true)
                            }}
                                sx={{ padding: '2px' }}>
                                <EditIcon />
                            </IconButton>
                        </Box>
                        <Box display='flex' gap='10px'>
                            <Button
                                onClick={() => { setAutoCreateDialogOpen(true) }}
                                size='small' variant="outlined" component="label" startIcon={<AutoFixHigh />} >
                                Auto Create
                            </Button>
                            <Button
                                onClick={() => {
                                    setExcelImportError('')
                                    setFileUploadOpen({ status: true })
                                }
                            }
                                size='small' variant="outlined" component="label" startIcon={<FolderIcon />} >
                                Import
                            </Button>
                            <Button
                                sx={{ height: 'fit-content', boxShadow: 'none' }}
                                variant='contained'
                                color='secondary'
                                size='small'
                                onClick={() => {
                                    setDeleteDialogOpen({ status: true, deleteItem: 'test' })
                                }}>Delete Test</Button>
                        </Box>
                    </Box>
                    {(loading) ? <LinearProgress /> : (<Box display={'flex'} gap={'10px'} flexDirection={'column'}>
                        {sortedQuestions.map((val, index) => {
                            return (
                                <QuestionAccordion
                                    changePosition={changePosition}
                                    deleteDialogOpen={deleteDialogOpen}
                                    setDeleteDialogOpen={setDeleteDialogOpen}
                                    test={test}
                                    testId={testId}
                                    rowIndex={index}
                                    questionIndex={(((page - 1 )) * 20) + index}
                                    questions={questions}
                                    val={val}
                                />
                            )
                        }
                        )}
                    </Box>)}
                    <Box display={'flex'} justifyContent={'center'} mt={8}>
                        <Box display={'flex'} gap={8} justifyItems={'center'} justifyContent={'space-between'} mt={4} style={{
                            display: 'flex',
                            border: 'solid 2px block',
                            borderRadius: '14px',
                        }}>

                            <Box
                                onClick={() => {
                                    if ( page > 1){
                                        setLoading(true)
                                        setPage(page -1)
                                        setTimeout(() => {
                                            setLoading(false)
                                        },200)
                                        // dispatch(questionsListThunk(pagination?.prev))
                                        // .then(unwrapResult)
                                        // .then(() => {
                                        //     setPage(page -  1)
                                        // })
                                        // .finally(() => {
                                        //     setLoading(false)
                                        // })
                                    }
                                        
                                }}
                                style={{ cursor:  page > 1 ? 'pointer' : 'not-allowed', opacity:  page > 1 ? "100%" : '30%' }}>

                                <MdArrowBackIos size={25} />
                            </Box>
                            <Typography variant='h2'>{page}</Typography>
                            <Box ml={1}
                                onClick={() => {
                                    if (pagination?.next){
                                        setLoading(true)
                                        dispatch(questionsListThunk(pagination?.next))
                                        .then(unwrapResult)
                                        .then(() => {
                                            setPage(page + 1)
                                        })
                                        .finally(() => {
                                            setLoading(false)
                                        })
                                    }
                                }}
                                style={{ cursor: (pagination?.next) ? 'pointer' : 'not-allowed', opacity: (pagination?.next) ? "100%" : '30%' }}>
                                <MdArrowForwardIos size={25} />
                            </Box>
                        </Box>
                    </Box>

                    <Box p={6} mt={8} style={{ backgroundColor: '#fff', borderRadius: '14px' }}>
                        <Box  >
                            <Typography mb={3} variant='h4'>Add question</Typography>
                            <AddQuestionForm
                                test={test}
                                testId={testId}
                            />
                            {/* <Box display={'flex'} flexDirection={'column'} gap={2}>
                                <InputBaseCustom
                                    placeholder='Question'
                                    bgColor='#F8F0F8'
                                    edit={(edit.questionId === 'new')}
                                    setEdit={() => setEdit({ questionId: 'new', choiceId: null })}
                                    handleSave={(value) => {
                                        if (testId) {
                                            dispatch(questionsCreateThunk({ data: { question: value }, testId: testId }))
                                                .then(() => {
                                                    setSnackBarStatus({ open: true, message: 'Question Successfully Created', status: 'success' })
                                                }).catch(() => {
                                                    setSnackBarStatus({ open: true, message: 'Question Not Created', status: 'success' })

                                                })
                                        }
                                        setEdit({ questionId: null, choiceId: null })
                                    }}
                                />
                            </Box> */}
                        </Box>
                    </Box>
                </Box>
            </Box>

            {
                testId && <TestAddDialog
                    open={InputDialogOpen}
                    id={testId}
                    handleClose={() => { setInputDialogOpen(false) }}
                // onSubmit={(value) => {
                //     if (testId) {
                //         dispatch(testUpdateThunk({ id: testId, data: { name: value } }))
                //             .then(() => setInputDialogOpen(false))
                //     }
                // }}
                />
            }
            {
                <DeleteWarningDialog
                    deleteDialogOpen={deleteDialogOpen.status}
                    handlerCloseDialog={(canDelete) => {
                        if (testId && canDelete && deleteDialogOpen.deleteItem === 'test') {
                            dispatch(testDeleteThunk(testId))
                                .then(unwrapResult)
                                .then(() => navigate('/dashboard/tests'))
                        }
                        if (deleteDialogOpen.deleteItem === 'question' && deleteDialogOpen.question && test) {
                            dispatch(questionsDeleteThunk({ test: test?.url, id: deleteDialogOpen.question?.id }))
                                .then(() => {
                                    dispatch(testListThunk())
                                    if (testId && test) {
                                        dispatch(questionsListThunk(test?.links.getQuestionList))
                                            .finally(() => {
                                                setLoading(false)
                                            })
                                    }
                                    setDeleteDialogOpen({ status: false })
                                })
                        }
                        if (!canDelete)
                            setDeleteDialogOpen({ status: false })
                    }}
                />
            }
            <Dialog open={fileUploadOpen.status} maxWidth='xs'>
                <CustomDropZone
                    error={excelImportError !== '' ? excelImportError : undefined}
                    handleSubmit={(file, progressFn) => {
                        if (testId) {
                            if (fileUploadOpen.question) {
                                dispatch(questionsUpdateThunk({ data: { file: file }, questionId: fileUploadOpen.question, testId: testId }))
                                    .then(unwrapResult)
                                    .then(() => {
                                        toast.success('Question Successfully Created')
                                        // setSnackBarStatus({ open: true, message: 'Question Successfully Updated', status: 'success' })
                                    }).catch(() => {
                                        toast.error('Error')
                                        // setSnackBarStatus({ open: true, message: 'Error', status: 'fail' })
                                    })
                            } else {
                                dispatch(excelImportThunk({ testId: testId, data: file, progressFn }))
                                    .then(unwrapResult)
                                    .then(() => {
                                        toast.success('Question Successfully Created')
                                        dispatch(testListThunk())
                                        if (testId && test) {
                                            dispatch(questionsListThunk(test?.links.getQuestionList))
                                                .finally(() => {
                                                    setLoading(false)
                                                })
                                        }
                                        setFileUploadOpen({ status: false })
                                        // setFileUploadOpen()
                                    })
                                    .catch((err) => {
                                        if ( 'row' in err) {
                                            toast.error("Error" + " in" + err.row)
                                            setExcelImportError("Error" + " in row " + err.row)
                                        }
                                    })
                            }

                        }
                    }}
                    handleClose={() => { setFileUploadOpen({ status: false }) }} />

            </Dialog>

            {testId && <AutoCreateForm handleClose={() => { setAutoCreateDialogOpen(false) }} open={autoCreateDialogOpen} testId={testId} />}

        </>
    )
}

export default QuestionsList