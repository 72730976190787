import { CloseFullscreenSharp, KeyboardArrowDown, KeyboardArrowUp, OpenInFullSharp } from '@mui/icons-material'
import { Accordion, AccordionDetails, Alert, Box, Button, Checkbox, Grid, IconButton, Snackbar, Typography } from '@mui/material'
import { unwrapResult } from '@reduxjs/toolkit'
import clsx from 'clsx'
import FillTheBlankField, { TemplateType } from 'components/FillTheBlankFiled/FillTheBlankField'
import InputBaseCustom from 'components/InputBaseCustom'
import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { QuestionWithPostionType, questionsListThunk, questionsUpdateThunk, selectQuestionApi, selectQuestionPagination } from 'store/reducers/question.reducer'
import AccordionStyles from 'styles/accordion.module.scss'
import { SeruDeleteDialogStatusType } from './QuestionsList'
import SetAnswerWarningDialog from 'components/setAnswerWarning'
import { ApiStatusEnum } from 'api/api'
type Props = {
    rowIndex: number
    questions: QuestionWithPostionType[]
    val: QuestionWithPostionType,
    questionIndex: number,
    changePosition: (data: { question: QuestionWithPostionType, position: number,reload?:boolean }) => void,
    testId?: number,
    test?: TestResponseType,
    deleteDialogOpen: SeruDeleteDialogStatusType,
    setDeleteDialogOpen: (val: SeruDeleteDialogStatusType) => void
}
const QuestionAccordion: React.FC<Props> = ({ val, questionIndex, changePosition, questions, testId, test, deleteDialogOpen, setDeleteDialogOpen, rowIndex }) => {
    const [accordionOpen, setAccordionOpen] = useState(false)
    const [questionArray, setQuestionArray] = React.useState<TemplateType[]>([''])
    const [apiError, setApiError] = React.useState<any>(undefined)
    const qApi = useAppSelector(selectQuestionApi)
    const pagination = useAppSelector(selectQuestionPagination)

    useEffect(() => {
        if (qApi.status !== ApiStatusEnum.FAILED) {
            setApiError(undefined)
        }
    }, [qApi])

    useEffect(() => {
        if (val.questionType !== 'multiple_choice') {
            let regex = /\[\[~choice~\]\]/g;

            let parts = val.question.split(regex);
            let arr: TemplateType[] = [];

            parts.forEach((part, index) => {
                const choiceIndex = arr.filter(e => typeof e !== 'string').length
                const choice = val.choices?.find(e => e.choicePosition === choiceIndex + 1)
                if (part === '') {
                    // arr.push({ value: choice?.choice ?? 'blank', index: choiceIndex + 1 });
                } else if (parts.length === index + 1) {
                    arr.push(part);
                } else {
                    arr.push(part, { value: choice?.choice ?? 'blank', index: choiceIndex + 1 });
                }
            });
            setQuestionArray(arr)
        }

    }, [])
    const [snackBarStatus, setSnackBarStatus] = useState<{ open: boolean, message?: string, status?: 'success' | 'fail' }>({ open: false })
    const [ansWarningStatus, setAnsWarningStatus] = useState<{ open: boolean, ids?: ChoiceUrlParamsType, question?: QuestionWithPostionType, isAnswer?: boolean }>({ open: false })
    const [edit, setEdit] = useState<{ questionId: number | null | 'new', choiceId: number | null | 'new', isVideo?: boolean }>({ questionId: null, choiceId: null })
    const dispatch = useAppDispatch()
    return (
        <>
            <Box className={clsx(AccordionStyles.container)} key={val.url} style={{ backgroundColor: '#fff', borderRadius: '14px' }}>
                <Box sx={{ display: 'flex', marginLeft: { xs: '8px', md: '20px' } }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: { xs: '25px', md: '35px' } }}>
                        <Box mb={5} display='flex' flexDirection='column'>
                            <IconButton
                                style={{ padding: '4px' }}
                                disabled={questionIndex + 1 === 1} onClick={() => {
                                    changePosition({ question: val, position: questionIndex,reload:rowIndex === 0 })
                                }
                                } >
                                <KeyboardArrowUp />
                            </IconButton>
                            <IconButton
                                style={{ padding: '4px' }}
                                disabled={pagination.next === undefined}
                                onClick={() => {
                                    if(questions.length === questionIndex + 1 && pagination.next){
                                        dispatch(questionsListThunk(pagination?.next))
                                    }
                                    changePosition({ question: val, position: questionIndex + 2,reload:rowIndex === 19 })
                                }

                                }
                            >
                                <KeyboardArrowDown />
                            </IconButton>
                        </Box>
                    </Box>
                    <Box sx={{ padding: { xs: '8px 8px 8px 4px', md: '8px' }, display: 'flex', flexDirection: 'column', flexGrow: 1 }} >
                        <Accordion style={{ boxShadow: 'none', backgroundColor: 'transparent', padding: '10px' }} expanded={accordionOpen} >
                            <Box
                                display={'flex'}
                                flexDirection='column'
                                sx={{ padding: { md: '10px' } }}
                            >
                                <Box display='flex' alignItems='center' justifyContent='space-between' height='50px'>
                                    <Typography variant='h4'>{`Question ${questionIndex + 1}`}</Typography>
                                    <Box >
                                        {(accordionOpen) ? (
                                            <Box>
                                                <Button onClick={
                                                    () => { setDeleteDialogOpen({ status: true, deleteItem: 'question', question: val }) }
                                                } size='small' variant='outlined' color='secondary'>
                                                    Delete
                                                </Button>
                                                <IconButton
                                                    onClick={() => {
                                                        setAccordionOpen(!accordionOpen)
                                                    }}>
                                                    <CloseFullscreenSharp />
                                                </IconButton>
                                            </Box>

                                        ) : (
                                            <IconButton
                                                className={clsx(AccordionStyles.expandIcon)}
                                                onClick={() => {
                                                    setAccordionOpen(!accordionOpen)
                                                }}>
                                                <OpenInFullSharp />

                                            </IconButton>
                                        )}
                                    </Box>
                                </Box>
                                <Box flexGrow={1}>
                                    {accordionOpen ? (
                                        <Box>
                                            <Box display={'flex'} flexDirection={'column'} gap={3}>
                                                {val.questionType === 'multiple_choice' ? (
                                                    <InputBaseCustom
                                                        label='Question'
                                                        bgColor='#F8F0F8'
                                                        edit={(val.id === edit.questionId && edit.choiceId === null && edit.isVideo !== true)}
                                                        setEdit={() => setEdit({ questionId: val.id, choiceId: null })}
                                                        value={val.question}
                                                        handleSave={(value) => {
                                                            if (testId) {
                                                                dispatch(questionsUpdateThunk({ data: { question: value }, questionId: val.id, testId: testId }))
                                                                    .then(unwrapResult)
                                                                    .then(() => {
                                                                        setSnackBarStatus({ open: true, message: 'Question Successfully Updated', status: 'success' })
                                                                    }).catch((error) => {
                                                                        setApiError(error)
                                                                        setSnackBarStatus({ open: true, message: 'Question Not Updated', status: 'fail' })
                                                                    })
                                                            }
                                                            setEdit({ questionId: null, choiceId: null })
                                                        }}
                                                    />
                                                ) : (
                                                    <FillTheBlankField
                                                        questionArray={questionArray}
                                                        setQuestionArray={setQuestionArray}
                                                        choices={val.choices.map((e, i) => ({ choice: e.choice, index: i, isAnswer: e.isAnswer.toString() }))}
                                                        label="Question"
                                                        placeholder='Question'
                                                        bgColor='#F8F0F8'
                                                        onChange={(val) => { }}
                                                        value={""}
                                                        handleSave={() => {
                                                            const questionString = questionArray.reduce((map: string, val: any) => {
                                                                if (typeof val === 'string') {
                                                                    return map + val
                                                                } else {
                                                                    return map + " [[~choice~]] "
                                                                }
                                                            }, "")

                                                            const selectedChoiceArr = [...questionArray].filter(e => typeof e !== 'string')
                                                            const choices = val.choices.map((item) => {
                                                                const find: any = selectedChoiceArr.find(e => (typeof e !== 'string' && e.value === item.choice))
                                                                return find ? { choice: find.value, isAnswer: true, choicePosition: find.index } : { choice: item.choice, isAnswer: false }
                                                            })
                                                            if (testId) {
                                                                dispatch(questionsUpdateThunk({ data: { question: questionString, choices: choices }, questionId: val.id, testId: testId }))
                                                                    .then(unwrapResult)
                                                                    .then(() => {
                                                                        setSnackBarStatus({ open: true, message: 'Question Successfully Updated', status: 'success' })
                                                                    }).catch((error) => {
                                                                        setApiError(error)
                                                                        setSnackBarStatus({ open: true, message: 'Question Not Updated', status: 'fail' })
                                                                    })
                                                            }
                                                        }}

                                                    />
                                                )}

                                                <InputBaseCustom
                                                    acceptNull
                                                    label='Video url'
                                                    bgColor='#F8F0F8'
                                                    edit={(val.id === edit.questionId && edit.choiceId === null && edit.isVideo === true)}
                                                    setEdit={() => setEdit({ questionId: val.id, choiceId: null, isVideo: true })}
                                                    value={val.videoUrl ?? ""}
                                                    handleSave={(value) => {
                                                        if (testId) {
                                                            dispatch(questionsUpdateThunk({ data: { videoUrl: value === '' ? null : value }, questionId: val.id, testId: testId }))
                                                                .then(unwrapResult)
                                                                .then(() => {
                                                                    setSnackBarStatus({ open: true, message: 'Question Successfully Updated', status: 'success' })
                                                                }).catch(() => {
                                                                    setSnackBarStatus({ open: true, message: 'Error', status: 'fail' })
                                                                })
                                                        }
                                                        setEdit({ questionId: null, choiceId: null })
                                                    }}
                                                />
                                                {/* <Box display={'flex'} gap={2} alignItems={'flex-end`'}>
                                                                    {val.file && <img style={{ width: '150px', height: '75px', objectFit: 'contain' }} src={val.file} alt="" />}
                                                                    <Box mb={2}>
                                                                        <Button
                                                                            onClick={() => {
                                                                                setFileUploadOpen({ status: true, question: val.id })
                                                                            }}
                                                                            size='small' variant="outlined" component="label" startIcon={<FolderIcon />} >
                                                                            {val.file ? 'Update File' : 'Choose File'}
                                                                           
                                                                        </Button>
                                                                    </Box>
                                                                </Box> */}

                                            </Box>
                                            <Box display='flex' alignItems='center' gap='5px' mt={2}>
                                                <Checkbox sx={{ padding: '2px' }} checked={val.isTrial} onChange={(event) => {

                                                    if (testId && test) {
                                                        dispatch(questionsUpdateThunk({
                                                            data: { tests: val.tests.filter(e => e.test === test?.url).map((e, i) => ({ ...e, isTrial: event.target.checked, position: questionIndex + 1 })) },
                                                            questionId: val.id,
                                                            testId: testId
                                                        }))
                                                    }

                                                }} />
                                                <Typography variant='body2' fontStyle='italic'>Add to trial questions</Typography>
                                            </Box>
                                        </Box>

                                    ) : (
                                        val.questionType === 'fill_in_the_blank' ? <Box sx={{ width: '100%', lineHeight: 2 }}>
                                            {questionArray.map((item, index) => {
                                                return (
                                                    <span key={index}>{typeof item === 'string' ? item : (<span style={{ display: 'inline-flex', backgroundColor: '#EDF8FF', margin: '02px' }}>{item.value}</span>)}</span>
                                                )
                                            })}
                                        </Box>
                                            : <Typography>{val.question}</Typography>
                                    )}
                                </Box>

                            </Box>
                            <AccordionDetails>
                                <Grid container columnSpacing={6} rowSpacing={4}>

                                    {val?.choices.map((e) => (
                                        <Grid item
                                            key={e.id}
                                            xs={12} md={6}
                                        >
                                            <InputBaseCustom
                                                bgColor='#EDF8FF'
                                                isChoice
                                                isAnswer={e.isAnswer}
                                                edit={(val.id === edit.questionId && e.id === edit.choiceId)}
                                                setEdit={() => setEdit({ questionId: val.id, choiceId: e.id })}
                                                value={e.choice}
                                                setIsAnswer={val.questionType === 'multiple_choice' ? () => {
                                                    // if (testId) { dispatch(setAnswerThunk({ testId: testId, questionId: val.id, choiceId: e.id })) }
                                                    if (testId) {
                                                        setAnsWarningStatus({
                                                            open: true,
                                                            ids: { testId: testId, questionId: val.id, choiceId: e.id },
                                                            question: val,
                                                            isAnswer: e.isAnswer
                                                        })
                                                    }
                                                } : undefined}
                                                handleSave={(value) => {
                                                    // dispatch(qChoiceUpdateThunk({ question: val, choiceId: e.id, url: e.url, data: { choice: value } }))
                                                    if (testId && test)
                                                        dispatch(questionsUpdateThunk({
                                                            data: { choices: val.choices.map(choice => e.id === choice.id ? { ...choice, choice: value } : choice) },
                                                            questionId: val.id,
                                                            testId: testId
                                                        }))
                                                            .then(() => {
                                                                setSnackBarStatus({ open: true, message: 'Choice Successfully updated', status: 'success' })
                                                            }).catch((error) => {
                                                                setApiError(error)
                                                                setSnackBarStatus({ open: true, message: 'Choice Not Updated', status: 'fail' })
                                                            })
                                                    setEdit({ questionId: null, choiceId: null })
                                                }}
                                            />
                                        </Grid>

                                    ))}
                                    {val.choices.length < 4 &&
                                        <Grid item xs={12} md={8}>
                                            <InputBaseCustom
                                                bgColor='#EDF8FF'
                                                edit={val.id === edit.questionId && edit.choiceId === 'new'}
                                                setEdit={
                                                    () => setEdit({ questionId: val.id, choiceId: 'new' })
                                                }
                                                handleSave={(value) => {

                                                    if (testId && test)
                                                        dispatch(questionsUpdateThunk({
                                                            data: { choices: [...val.choices, { choice: value, isAnswer: false }] },
                                                            questionId: val.id,
                                                            testId: testId
                                                        }))
                                                            .then(() => {
                                                                setSnackBarStatus({ open: true, message: 'Choice Successfully updated', status: 'success' })
                                                            }).catch(() => {
                                                                setSnackBarStatus({ open: true, message: 'Choice Not Updated', status: 'fail' })
                                                            })

                                                    setEdit({ questionId: null, choiceId: null })

                                                }}
                                            />
                                        </Grid>}
                                </Grid>

                            </AccordionDetails>
                        </Accordion>
                    </Box>
                </Box>
                <Box px={8} pb={8}>
                    <Typography color={'red'}>{apiError && apiError?.question && apiError?.question[0] && 'Question: ' + apiError?.question[0]}</Typography>
                    <Typography color={'red'}>{apiError && apiError?.choices && apiError?.choices[0] && apiError?.choices[0].choice && apiError?.choices[0].choice[0] && 'Choice: ' + apiError?.choices[0].choice[0]}</Typography>
                    <Typography color={'red'}>{apiError && apiError?.detail && apiError?.detail}</Typography>
                </Box>

            </Box>
            {
                snackBarStatus.open && <Snackbar open={snackBarStatus.open} autoHideDuration={6000} onClose={() => setSnackBarStatus({ open: false })}>
                    <Alert
                        severity={snackBarStatus.status === 'success' ? 'success' : 'error'}
                        style={{ backgroundColor: snackBarStatus.status === 'success' ? 'green' : 'red', color: '#fff', width: '100%' }}
                        onClose={() => setSnackBarStatus({ open: false })}
                    >
                        {snackBarStatus.message ?? ''}
                    </Alert>
                </Snackbar>
            }
            {

                ansWarningStatus.open && <SetAnswerWarningDialog
                    open={ansWarningStatus.open}
                    handleFlow={(isSetAnswer) => {
                        if (ansWarningStatus.ids && isSetAnswer && ansWarningStatus.question && testId) {
                            dispatch(questionsUpdateThunk({
                                data: { choices: ansWarningStatus.question.choices.map(choice => ansWarningStatus.ids?.choiceId === choice.id ? { ...choice, isAnswer: !choice.isAnswer ? true : false } : choice) },
                                questionId: ansWarningStatus.question.id,
                                testId: testId
                            }))
                            // dispatch(qSetAnswerThunk({ urlParams: { ...ansWarningStatus.ids }, question: ansWarningStatus.question, isUnset: ansWarningStatus.isAnswer ? true : false }))
                        }
                        setAnsWarningStatus({ open: false })
                    }}
                />
            }
        </>
    )
}

export default QuestionAccordion