import { Box, Button, Divider, LinearProgress, Paper, Typography } from '@mui/material'
import { ApiStatusEnum } from 'api/api'
import DetailsCard from 'components/DetailsCard'
import React, { useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { answerListThunk, selectAllAnswers, selectListAnswerApi } from 'store/reducers/listAnswer.reducer'
import { selectTestattemptById } from 'store/reducers/testAttempt.reducer'
const AttemptsDetails = () => {
  const { taId } = useParams()
  const testAttemptId = useMemo(() => {
    if (taId) return parseInt(taId)
  }, [taId])

  const dispatch = useAppDispatch()

  const attemptData = useAppSelector(selectAllAnswers)
  useEffect(() => {
    if (testAttemptId) {
      dispatch(answerListThunk(testAttemptId))
    }
  }, [dispatch, testAttemptId])

  const testAttempt = useAppSelector(state => selectTestattemptById(state, testAttemptId ?? ''))
  const api = useAppSelector(selectListAnswerApi)
  const navigate = useNavigate()
  return (
    <Box >
      <Box mb={3}>
        <Button size='small' style={{ width: '50px' }} variant='outlined' onClick={() => navigate(-1)}>Back</Button>
      </Box>
      {api.status === ApiStatusEnum.LOADING ? <LinearProgress /> : (<Box>
        <DetailsCard
        title='Attempts Details'
        values={
          [
            {title:'Test Name',value:testAttempt?.test.name ?? 'N/A'},
            {title:'User Name',value:testAttempt?.user.displayName ?? 'N/A'},
            {title:'Score',value:testAttempt?.rightAnswerCount ?? 'N/A'},
            {title:'Time Taken',value:testAttempt?.timeTaken ?? 'N/A'},
          ]
        }
        />
        <Box style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', gap: '10px' }}>

          {attemptData.map((val, index) => {
            return (
              <Box key={val.id} style={{ backgroundColor: '#fff', borderRadius: '14px', padding: '30px 20px 20px 30px', display: 'flex', flexDirection: 'column' }} >
                <Typography mb={3} variant='h4'>{`Question ${index + 1}`}</Typography>
                <Box>
                  <Typography>{val.question.question}</Typography>
                  <Box display='flex' marginTop={'10px'} gap='5px'>
                    <Typography variant='body2'>Submitted answer :</Typography>
                    <Typography fontWeight={600} color={val.isCorrect ? 'green' : 'red'}>{val.choice.choice}</Typography>
                  </Box>
                </Box>
              </Box>
            )
          })}
        </Box>
      </Box>)}
    </Box>
  )
}

export default AttemptsDetails