// import { ApiStatusEnum } from "api/api";
import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router";
import { dashboardInitPath } from "shared/appConstants";


const Dashboard = () => {
    const location = useLocation()
    const navigate = useNavigate()
    useEffect(() => {
        if(location.pathname === '/dashboard'){
            navigate(dashboardInitPath)
        }
    }, [location, navigate])

    return <Outlet/>
}
export default Dashboard;
