import { Box, Divider, Grid, Paper, Typography } from '@mui/material'
import React from 'react'
import { useAppSelector } from 'store/hooks'
import { selectAllConfig } from 'store/reducers/dynamicConfig.reducer'
import ConfigForm from './ConfigForm'

const ConfigPage = () => {
  const configs = useAppSelector(selectAllConfig)
  return (
    <Box maxWidth={'md'} sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
    }} >
      {
        configs.map((e, index) => {
          return (
            <Box p={5} component={Paper} key={index}>
              <ConfigForm val={e} />
            </Box>
          )
        })
      }
      <Box p={5} component={Paper} >
        <Typography>Add New</Typography>
        <Divider sx={{my:2}}/>
        <ConfigForm isNew />
      </Box>
    </Box>
  )
}

export default ConfigPage