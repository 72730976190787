import Dashboard from 'containers/Authorized/Dashboard'
import DashboardHome from 'containers/Authorized/Dashboard/DashboardHome'
import attemptsRoutes from 'routes/authorizedRoute/dashboardRoute/attempts'
import testsRoutes from 'routes/authorizedRoute/dashboardRoute/tests'
import usersRoutes from 'routes/authorizedRoute/dashboardRoute/users'
import configRoutes from 'routes/authorizedRoute/dashboardRoute/seruConfig'
import { DetailedRouteConfig } from 'routes/routes.typing'



const routes: DetailedRouteConfig = {
    path: '/dashboard',
    element: <Dashboard />,
    children: [
        attemptsRoutes,
        testsRoutes,
        usersRoutes,
        configRoutes
        // { 
        //     path:'/dashboard/home',
        //     element: <DashboardHome/>
        // },
    ]
}

export default routes;

