import React, { useState } from 'react'
import { Form, Formik } from 'formik';
import * as yup from 'yup'
import { IApiState } from 'api/api';
import PaginatedToolbar from 'components/Tables/PaginatedTable/PaginatedToolbar';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import AppTextField from '@crema/core/AppFormComponents/AppTextField';


interface EnhancedTableToolbarProps {
    changeFilter: (filters: Partial<UserFilterParams>) => void,
    api?: IApiState,
    action?: React.ReactNode,
}




export type UsersTableFilterFormType = {
    email: string
}



export const filterInitValues: UsersTableFilterFormType = {
    email: ''
}

const formToFilterArgs = (values: UsersTableFilterFormType): Partial<UserFilterParams> => {
    const filterParams: Partial<UserFilterParams> = {}
    // if (values.hospitalDoctor)
    //   filterParams.hospitalDoctor = parseInt(values.hospitalDoctor)
    if (values.email)
        filterParams.email = values.email

    return filterParams
}

const UsersTableToolbar: React.FC<EnhancedTableToolbarProps> = ({ changeFilter, api, action }) => {
    const [showFilters, setShowFilters] = useState(false);
    const handleShowFilter = () => setShowFilters(!showFilters)
    // const isSm = useMediaQuery((theme: CremaTheme) => theme.breakpoints.down('sm'))

    const handleFormChange = (values: UsersTableFilterFormType) => {
        changeFilter(formToFilterArgs(values))
    }
    const validationSchema = yup.object({
        email: yup.string()
    })
    return (
        <PaginatedToolbar action={action} title="Users" handleShowFilter={handleShowFilter} showFilters={showFilters}>
            <Formik
                initialValues={filterInitValues}
                validationSchema={validationSchema}
                onSubmit={(values, actions) => handleFormChange(values)}
            >
                {({ values, setFieldValue, isValid, errors, resetForm }) => {
                    return (
                        <Form noValidate autoComplete="off">
                            <Grid container direction={ "row"} spacing={6} >

                                <Grid item container md={4} xs={12} spacing={2}>
                                    <Grid item style={{ flexGrow: 1 }}>
                                        <AppTextField
                                            name='email'
                                            style={{ width: '100%' }}
                                            size="small"
                                            label="User Name"
                                            margin="none"
                                        />
                                    </Grid>
                                </Grid>
                                
                                <Grid item container  spacing={2} xs={12} md={4}>
                                    <Grid item>
                                        <Button sx={{width:'120px',height:'36px',boxShadow:'none'}}  variant="contained" size="small" color="primary" type="submit">
                                            {"Apply"}
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <IconButton onClick={() => resetForm()} size="small">
                                            <RefreshIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Form>
                    )
                }}
            </Formik>
        </PaginatedToolbar>
    );
};

export default React.memo(UsersTableToolbar)