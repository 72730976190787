import { createAsyncThunk, createEntityAdapter, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { ApiStatusEnum, IApiState } from "api/api";
import testsApi from "api/testsApi";
import { AppState } from "store/store";

const testAdapter = createEntityAdapter<TestResponseType>({
    sortComparer: (a, b) => a.position.toString().localeCompare(b.position.toString())
})

type InitialStateType = {
    api: IApiState
}

const addOnInitialState: InitialStateType = {
    api: {
        status: ApiStatusEnum.IDLE,
        error: null
    }
}

export const testListThunk = createAsyncThunk(
    'test/list',
    async (_: undefined, { rejectWithValue ,dispatch}) => {
        
        try {
            const response = await testsApi.listTests()
            return response.data
        } catch (error: any) {
            return rejectWithValue(error.response.data)
        }
    }
)
export const testCreateThunk = createAsyncThunk(
    'test/create',
    async (payload: TestCreateType, { rejectWithValue }) => {
        try {
            const response = await testsApi.createTest(payload)
            return response.data
        } catch (error: any) {
            return rejectWithValue(error.response.data)
        }
    }
)
export const testGetThunk = createAsyncThunk(
    'test/get',
    async (payload:number, { rejectWithValue }) => {
        try {
            const response = await testsApi.getTest(payload)
            // return {...response.data,trialQuestions:[]}

        } catch (error: any) {
            return rejectWithValue(error.response.data)
        }
    }
)
export const testDeleteThunk = createAsyncThunk(
    'test/delete',
    async (payload:number, { rejectWithValue }) => {
        try {
            await testsApi.deleteTest(payload)
            return payload

        } catch (error: any) {
            return rejectWithValue(error.response.data)
        }
    }
)
export const testUpdateThunk = createAsyncThunk(
    'test/update',
    async (payload:{id:number,data:Partial<TestCreateType>}, { rejectWithValue }) => {
        try {
            const response = await testsApi.updateTest(payload.data,payload.id)
            return response.data

        } catch (error: any) {
            return rejectWithValue(error.response.data)
        }
    }
)

export const testAutoCreateThunk = createAsyncThunk(
    'test/autoCreate',
    async (payload:{id:number,data:AutoCreateType}, { rejectWithValue }) => {
        try {
            const response = await testsApi.autoCreate(payload.id,payload.data)
            return response.data

        } catch (error: any) {
            return rejectWithValue(error.response.data)
        }
    }
)

export const testSlice = createSlice({
    name: 'test',
    initialState: testAdapter.getInitialState(addOnInitialState),
    reducers: {
        resetState(state) {
            state.api = {
                status: ApiStatusEnum.IDLE
            }
            testAdapter.removeAll(state)
        }
    },
    extraReducers: builder => {
        builder.addCase(testListThunk.fulfilled, (state, action) => {
            state.api = {
                status: ApiStatusEnum.SUCCEEDED,
                error: null
            }
            testAdapter.upsertMany(state, action.payload)
        })

        builder.addCase(testCreateThunk.fulfilled, (state, action) => {
            state.api = {
                status: ApiStatusEnum.SUCCEEDED,
                error: null
            }
            testAdapter.upsertOne(state, action.payload)
        })
        builder.addCase(testDeleteThunk.fulfilled, (state, action) => {
            state.api = {
                status: ApiStatusEnum.SUCCEEDED,
                error: null
            }
            testAdapter.removeOne(state, action.payload)
        })
        builder.addCase(testUpdateThunk.fulfilled, (state, action) => {
            state.api = {
                status: ApiStatusEnum.SUCCEEDED,
                error: null
            }
            testAdapter.upsertOne(state, action.payload)
        })
        builder.addCase(testAutoCreateThunk.fulfilled, (state, action) => {
            state.api = {
                status: ApiStatusEnum.SUCCEEDED,
                error: null
            }
            testAdapter.upsertOne(state, action.payload)
        })


        builder.addMatcher(isAnyOf(
            testListThunk.pending,
            testCreateThunk.pending,
            testDeleteThunk.pending,
            testUpdateThunk.pending,
            testAutoCreateThunk.pending
        ), (state, action) => {
            state.api = {
                status: ApiStatusEnum.LOADING,
                error: null
            }
        })

        builder.addMatcher(isAnyOf(
            testListThunk.rejected,
            testCreateThunk.rejected,
            testDeleteThunk.rejected,
            testUpdateThunk.rejected,
            testAutoCreateThunk.rejected
        ), (state, action) => {
            state.api = {
                status: ApiStatusEnum.FAILED,
                error: action.payload
            }
        })
    }
})

export default testSlice.reducer

export const { resetState: resetTestState } = testSlice.actions

export const
    {
        selectAll: selectAllTest,
        selectById:selectTestById
    } = testAdapter.getSelectors((state: AppState) => state.test)

export const selectTestApi = (state:AppState)=>state.test.api
