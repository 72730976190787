import React from "react";
import clsx from "clsx";
import AppContentView from "@cremaMods/core/AppContentView";
import AppFixedFooter from "../../../../@crema/core/AppLayout/HorDarkLayout/AppFixedFooter";
import AppHeader from "../../../../@crema/core/AppLayout/HorDarkLayout/AppHeader";
import AppSidebar from "../../../../@crema/core/AppLayout/HorDarkLayout/AppSidebar";
import { useLayoutContext } from "../../../../@crema/utility/AppContextProvider/LayoutContextProvider";
import AppThemeSetting from "../../../../@crema/core/AppThemeSetting";
import HorDarkWrapper from "../../../../@crema/core/AppLayout/HorDarkLayout/HorDarkWrapper";
import MainContent from "../../../../@crema/core/AppLayout/HorDarkLayout/MainContent";
import { LayoutType } from "@cremaMods/shared/constants/AppEnums";
import HorDarkContainer from "../../../../@crema/core/AppLayout/HorDarkLayout/HorDarkContainer";

const HorDarkLayout:  React.FC<React.PropsWithChildren> = ({children}) => {
  const { footer, layoutType, footerType } = useLayoutContext();

  return (
    <HorDarkContainer
      className={clsx({
        boxedLayout: layoutType === LayoutType.BOXED,
        framedLayout: layoutType === LayoutType.FRAMED,
      })}
    >
      <HorDarkWrapper
        className={clsx("horDarkWrapper", {
          appMainFooter: footer && footerType === "fluid",
          appMainFixedFooter: footer && footerType === "fixed",
        })}
      >
        <AppSidebar />

        <MainContent>
          <AppHeader />
          <AppContentView>
            {children}
          </AppContentView>
          <AppFixedFooter />
        </MainContent>
        <AppThemeSetting />
      </HorDarkWrapper>
    </HorDarkContainer>
  );
};

export default HorDarkLayout;
