
import { DetailedRouteConfig } from 'routes/routes.typing';
import Config from '../../../../containers/Authorized/Dashboard/SeruConfig'
import ConfigPage from 'containers/Authorized/Dashboard/SeruConfig/ConfigPage';
import SeruConfig from '../../../../containers/Authorized/Dashboard/SeruConfig';

const routes :DetailedRouteConfig = {
    path:'/dashboard/config',
    element:<SeruConfig/>,
    children:[
        {
            path: '/dashboard/config',
            element: <ConfigPage/>
        },
    ]
}

export default routes