import { TextField, TextFieldProps } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { useField } from 'formik'
import React from 'react'

type FormikDateFieldProps = { 
    name: string, 
    label?: string, 
    inputFormat: string, 
    textFieldProps?: TextFieldProps ,
    onChangeDate?: (value: Date | null) => void,
    shouldDisableDate?: ((day: Date) => boolean),
    size?: 'small' | 'medium'
}

const FormikDateField: React.FC<FormikDateFieldProps> = ({ name, onChangeDate, shouldDisableDate, ...rest }) => {
    const [field, meta, actions] = useField(name)
    const errorText = meta.error && meta.touched ? meta.error : '';
    return (
        <DatePicker
            label={rest.label}
            inputFormat={rest.inputFormat}
            value={field.value === '' ? null : field.value}
            shouldDisableDate={shouldDisableDate}
            onChange={(value: Date | null) => onChangeDate ? onChangeDate(value) : actions.setValue(value)}
            renderInput={(params) => <TextField 
                size={rest.size ? rest.size : undefined}
                {...rest.textFieldProps}
                {...params}
                error={!!errorText}
                helperText={errorText}
            />}
        />
            
    )
}

export default FormikDateField