import axios from 'axios'
import { servers } from 'shared/appConstants';

const commonHeaders = {
    'Content-Type': 'application/json'
}



export const apiInstance = axios.create({
    baseURL: servers.API_SERVER,
    headers: commonHeaders
})
