import { AxiosResponse } from "axios"

export const getImageFromResponse = (image: ImageResponseType, size: keyof ImageResponseType): string => {
    if (size === "large" && image?.large) return image.large
    if (size === "medium" && image?.medium) return image.medium
    if (size === "small" && image?.small) return image.small
    return image.full
  }

  const linkHeaderRegex = /<([^>]+)>;\s+rel="([^"]+)"/

  export const extractHeaderLink = (linkHeader: string) => {
    let result: { [key: string]: string } = {}
    linkHeader.split(',')
      .forEach(link => {
        let match = link.match(linkHeaderRegex)
        if (match)
          result[match[2]] = match[1]
      })
    return result
  }
  
  

  export const extractAxiosHeaderLink = <T>(response: AxiosResponse<T>, linkType: 'next' | 'prev' | 'current'): string => {
    if (response.headers['link']) {
      let links = extractHeaderLink(response.headers['link'])
      if (linkType in links) return links[linkType]
    }
    return ''
  }
  const apiUtil = {
    getImageFromResponse,
    extractAxiosHeaderLink
  }


  export default apiUtil