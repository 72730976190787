import { parseISO, format as dateFormat, parse as parseDate, subDays } from "date-fns"
import { apiDateFormat, apiTimeFormat, defaultDateFormat, defaultDateTimeFormat, defaultTimeFormat } from "shared/appConstants"


const dateTimeToString = (date: Date): string => dateFormat(date, defaultDateTimeFormat)

const isoDateToFormattedDateTime = (date: string) => {
    try {
        return dateTimeToString(parseISO(date))
    }
    catch (err) {
        return 'Invalid ISO String'
    }
}

const isoDateToDate = (date: string): Date | boolean => {
    try {
        return parseISO(date)
    }
    catch (err) {
        return false
    }
}

const apiDateToDate = (date: string) => {
    try {
        return parseDate(date, apiDateFormat, new Date())
    } catch (err) {
        throw err
    }
}

const apiDateToFormattedDate = (date: string) => {
    try {
        return dateFormat(apiDateToDate(date), defaultDateFormat)
    }
    catch (err) {
        return 'Invalid Date'
    }
}

const apiTimeToDate = (time: string) => {
    try {
        return parseDate(time, apiTimeFormat, new Date())
    }
    catch (err) {
        throw err
    }
}

const apiTimeToFormattedTime = (time: string) => {
    try {
        return dateFormat(apiTimeToDate(time), defaultTimeFormat)
    }
    catch (err) {
        return 'Invalid Time'
    }
}

const dateToDateString = (date: Date) => dateFormat(date, apiDateFormat)

const apiFromToTimeToFormattedTime = (from: string, to?: string): string => {
    try {
        let times: string[] = [apiTimeToFormattedTime(from)]
        if (to)
            times.push(apiTimeToFormattedTime(to))
        return times.join(' - ')
    }
    catch (err) {
        return 'Invalid Time'
    }
}



const timeStringToUTCDate = (time: string, decrement: number = 0) => {
    return decrement > 0 ?
        parseDate(`${time} +00:00`, `${apiTimeFormat} xxx`, subDays(new Date(), decrement)) :
        parseDate(`${time} +00:00`, `${apiTimeFormat} xxx`, new Date())
}

const dateToUTCTimeString = (date: Date): string =>
    `${date.getUTCHours().toString().padStart(2, '0')}:${date.getUTCMinutes().toString().padStart(2, '0')}:${date.getUTCSeconds().toString().padStart(2, '0')}`


const dateUtil = {
    dateTimeToString,
    isoDateToFormattedDateTime, 
    isoDateToDate,
    apiDateToDate,
    apiDateToFormattedDate,
    apiTimeToDate,
    apiTimeToFormattedTime,
    dateToDateString,
    apiFromToTimeToFormattedTime,
    timeStringToUTCDate,
    dateToUTCTimeString
}

export default dateUtil