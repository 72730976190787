import { localStorageKeys } from "shared/appConstants"
import localStorageUtil from "./localStorageUtil"

const { JWT_TOKEN } = localStorageKeys


const readJwt = (): string | undefined => {
    const jwt = localStorageUtil.readFromLocalStorage(JWT_TOKEN)
    if (jwt!==null) {
        return (jwt)
    }
    return undefined;
}

const writeJwt = (jwt: string | undefined) => {
    if (jwt !== undefined) {
        localStorageUtil.writeToLocalStorage(JWT_TOKEN, jwt)
    } else {
        localStorageUtil.removeFromLocalStorage(JWT_TOKEN)

    }
}





const authUtil = {
    readJwt, writeJwt
}

export default authUtil