import React, { useState } from "react";
import AppSidebar from "../../../../@crema/core/AppLayout/MiniSidebarToggle/AppSidebar";
import AppContentView  from "@cremaMods/core/AppContentView";
import AppThemeSetting from "../../../../@crema/core/AppThemeSetting";
import AppHeader from "../../../../@crema/core/AppLayout/MiniSidebarToggle/AppHeader";
import clsx from "clsx";
import Box from "@mui/material/Box";
import MiniSidebarToggleWrapper from "../../../../@crema/core/AppLayout/MiniSidebarToggle/MiniSidebarToggleWrapper";
import AppFixedFooter from "../../../../@crema/core/AppLayout/MiniSidebarToggle/AppFixedFooter";
import { useLayoutContext } from "../../../../@crema/utility/AppContextProvider/LayoutContextProvider";
import { LayoutType } from "@cremaMods/shared/constants/AppEnums";
import MiniSidebarToggleContainer from "../../../../@crema/core/AppLayout/MiniSidebarToggle/MiniSidebarToggleContainer";

const MiniSidebarToggle:  React.FC<React.PropsWithChildren> = ({children}) => {
  const [isCollapsed, setCollapsed] = useState(false);
  const { footer, layoutType, headerType, footerType } = useLayoutContext();

  return (
    <MiniSidebarToggleContainer
      className={clsx({
        boxedLayout: layoutType === LayoutType.BOXED,
        framedLayout: layoutType === LayoutType.FRAMED,
      })}
    >
      <MiniSidebarToggleWrapper
        className={clsx("miniSidebarToggleWrapper", {
          "mini-sidebar-collapsed": isCollapsed,
          appMainFooter: footer && footerType === "fluid",
          appMainFixedFooter: footer && footerType === "fixed",
          appMainFixedHeader: headerType === "fixed",
        })}
      >
        <AppSidebar />
        <Box className="mainContent">
          <AppHeader setCollapsed={setCollapsed} isCollapsed={isCollapsed} />
          <AppContentView >
            {children}
          </AppContentView>
          <AppFixedFooter />
        </Box>
        <AppThemeSetting />
      </MiniSidebarToggleWrapper>
    </MiniSidebarToggleContainer>
  );
};

export default MiniSidebarToggle;
