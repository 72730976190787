import { CssBaseline } from '@mui/material';
import Root from 'containers/Root';
import { Provider } from 'react-redux';
import { store } from 'store/store';
import 'api/interceptors'
import { AppContextProvider, AppLocaleProvider, AppThemeProvider } from '@crema';
import React from 'react';
import { BrowserRouter   } from 'react-router-dom';
import FirebaseAuthProvider from 'services/FirebaseAuthProvider';

function App() {
  return (
    <AppContextProvider>
      <Provider store={store}>
        <AppThemeProvider>
          <AppLocaleProvider>
            <BrowserRouter>
                <React.Fragment>
                  <CssBaseline />
                  <Root/>
                </React.Fragment>
            </BrowserRouter>
          </AppLocaleProvider>
        </AppThemeProvider>
      </Provider>
    </AppContextProvider>
  )
}

export default App;
