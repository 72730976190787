
import React, { useEffect } from 'react'
import { Outlet } from 'react-router'
import { useAppDispatch } from 'store/hooks'
import { testListThunk } from 'store/reducers/test.reducer'

const Tests = () => {
    const dispatch = useAppDispatch()
    useEffect(() => {
    dispatch(testListThunk())
    }, [dispatch])
    
    return <Outlet />
}

export default Tests