
import { DetailedRouteConfig } from "routes/routes.typing";
import Attempts from "containers/Authorized/Dashboard/attempts/Attempts";
import ListAttempts from "containers/Authorized/Dashboard/attempts/ListAttempts";
import AttemptsDetails from "containers/Authorized/Dashboard/attempts/AttemptsDetails";


const routes : DetailedRouteConfig = {
    path: '/dashboard/attempts',
    element: <Attempts/>,
    children: [
        {
            path: '/dashboard/attempts/view/:taId',
            element: <AttemptsDetails/>
        },
        {
            path: '/dashboard/attempts',
            element: <ListAttempts/>
        },
        
    ]
}

export default routes;