import React, { useEffect } from "react";
import {
  useLayoutActionsContext,
  useLayoutContext,
} from "@crema/utility/AppContextProvider/LayoutContextProvider";
import Layouts from "@crema/core/AppLayout/Layouts";
import { useUrlSearchParams } from "use-url-search-params";
import { useSidebarActionsContext } from "../../../@crema/utility/AppContextProvider/SidebarContextProvider";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { onNavCollapsed } from "store/reducers/setting.reducer";
import AuthWrapper from "@crema/core/AppLayout/AuthWrapper";
import AppContentView from "../AppContentView";


export type AppLayoutProps = {
  isAuthenticated: boolean,
}

const AppLayout: React.FC<React.PropsWithChildren<AppLayoutProps>> = ({children, isAuthenticated}) => {
  const { navStyle } = useLayoutContext();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { updateNavStyle } = useLayoutActionsContext();
  const { updateMenuStyle, setSidebarBgImage } = useSidebarActionsContext();
  const AppLayout = Layouts[navStyle];
  const [params] = useUrlSearchParams({}, {});

  useEffect(() => {
    if (params.layout) updateNavStyle(params.layout as string);
    if (params.menuStyle) updateMenuStyle(params.menuStyle as string);
    if (params.sidebarImage) setSidebarBgImage(true);
  }, [params, setSidebarBgImage, updateNavStyle, updateMenuStyle]);

  useEffect(() => {
    dispatch(onNavCollapsed());
  }, [dispatch, pathname]);

  // return <AppLayout/>

  return (
    <>
      {isAuthenticated ? (
        <AppLayout>
          {children}
          </AppLayout>
      ) : (
        <AuthWrapper>
          <AppContentView>
            {children}
            </AppContentView>
        </AuthWrapper>
      )}
    </>
  );
};

export default React.memo(AppLayout);
