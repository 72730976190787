import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// type SettingType = {
//     navCollapsed: boolean;
//     initialPath: string | undefined;
//   }
  


const settingSlice = createSlice({
    name: 'settings',
    initialState: {
        navCollapsed: false,
        initialPath: "/",
    },
    reducers: {
        toggleNavCollapsed(state){
            state.navCollapsed = !state.navCollapsed
        },
        onNavCollapsed(state){
            state.navCollapsed = false
        },
        setInitialPath(state, action:PayloadAction<string>){
            state.initialPath = action.payload
        }
    }
})


export default settingSlice.reducer

export const {toggleNavCollapsed, onNavCollapsed, setInitialPath} = settingSlice.actions