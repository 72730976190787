
import LoadingContainer from 'components/LoadingContainer';
import { onAuthStateChanged } from 'firebase/auth';
import { useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router'
import { auth } from 'services/firebase';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectAuthUser, setCurrentUser} from 'store/reducers/auth.reducer';

const Authorized = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch()
    const loginUser = useAppSelector(selectAuthUser)
    useEffect(() => {
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                const { displayName, email, emailVerified, isAnonymous, phoneNumber, photoURL, uid } = user
                dispatch(setCurrentUser({ displayName, email, emailVerified, uid, isAnonymous, phoneNumber, photoURL }))
               
            }
        })
    }, [dispatch])
    useEffect(() => {
    
            navigate('/dashboard/tests')
        
    }, [])


    return loginUser ? <Outlet /> : <LoadingContainer/>

}

export default Authorized