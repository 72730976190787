import { apiInstance } from "api/client";

const list = async (params: TestAttemptFilterParams) => {
    const { asc, ...rest } = params
    const apiParams: { [key: string]: any } = { ...rest }
    if (!asc) apiParams['o'] = `-${rest['o']}`
    return apiInstance.get<TestAttemptResponseType[]>(`/test-attempt/q-admin/`, { cvtResToCamel: true, params: {...apiParams}, cvtReqParamsToSnake: true })
}

const listWithUrl = async (url: string) =>
    apiInstance.get<TestAttemptResponseType[]>(url, { cvtResToCamel: true })

const getTestAttempt = async (testAttemptId: number) =>
    apiInstance.get<TestAttemptResponseType>(`/test-attempt/q-admin/${testAttemptId}/`, { cvtResToCamel: true })

 const listAnswer = async(testAttemptId:number) => 
 apiInstance.get<TestAttemptListAnswerType[]>(`test-attempt/q-admin/${testAttemptId}/answer/`,{cvtResToCamel:true})  

    const testAttemptApi = {list,listWithUrl,getTestAttempt,listAnswer}

    export default testAttemptApi