
// import Root from "containers/Root";
// import dashboardRoutes from "routes/dashboardRoute"
// import LoginForm from "containers/login/LoginForm";
// import { DetailedRouteConfig } from "./routes.typing";

import { Navigate, RouteObject } from "react-router";
import { DetailedRouteConfig } from "./routes.typing";

// const routes: DetailedRouteConfig[] = [

//   {
//     path: "/",
//     element: <Root />,
//     children: [
//       dashboardRoutes
//     ],
//   },
// ];

// export default routes;

export type GenerateRouteStructure = {
  isAuthenticated: boolean,
  authorizedRoute: DetailedRouteConfig,
  unAuthorizedRoute: DetailedRouteConfig
}

const generateRoutes = (structure: GenerateRouteStructure) => {
  const {
    isAuthenticated = false,
    authorizedRoute,
    unAuthorizedRoute,
  } = structure;

  const dynamicRoutes: RouteObject[] = [];

  if (authorizedRoute) {
    dynamicRoutes.push(
      routesGenerator(
        isAuthenticated, authorizedRoute, "authorized",
      )
    );
  }

  if (unAuthorizedRoute) {
    dynamicRoutes.push(
      routesGenerator(isAuthenticated, unAuthorizedRoute, "unauthorized")
    );
  }
  return dynamicRoutes;
};



type RouteType = 'anonymous' | 'authorized' | 'unauthorized'


const routesGenerator = (
  isAuthenticated: boolean = false,
  routeSet: DetailedRouteConfig,
  routeType: RouteType = "anonymous",
  fP: string = ""
): RouteObject => {

  const isAuthorized = routeType === "authorized";
  
  const {
    path = "",
    // permittedRole = RoutePermittedRole.User,
    element,
    children,
    fallbackPath = fP
  } = routeSet;
  return {
    element: (isAuthorized && isAuthenticated) || (!isAuthorized && !isAuthenticated) ? 
      element : <Navigate to={fallbackPath} replace />,
    path: path,
    children: children?.map(e => routesGenerator(isAuthenticated, e, routeType, fallbackPath))
  }
}



export default generateRoutes;