
import { Outlet } from 'react-router'
import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { selectTestAttemptsFilterParams, testAttemptListThunk } from 'store/reducers/testAttempt.reducer'

const Attempts = () =>{ 
    const dispatch = useAppDispatch()
    const filterArgs = useAppSelector(selectTestAttemptsFilterParams)
    useEffect(() => {
      dispatch(testAttemptListThunk())
    }, [dispatch,filterArgs])
return <Outlet />
}

export default Attempts