import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useDropzone } from 'react-dropzone';
import { drop } from 'lodash';
import FileRow, { FileType } from '../components/FileRow';
import { UploadModern } from '../components';
import { AppList } from '@crema';
type Props = {
    isSingle?: boolean,
    onUpload: (file: File[]) => void,
    onDelete: () => void,
    showAttachments?:boolean,
    attachments:any[]

}

const Basic: React.FC<Props> = ({ isSingle, onDelete, onUpload,showAttachments,attachments}) => {
    const dropzone = useDropzone({ multiple: isSingle ? false : true });
    const [uploadedFiles, setUploadedFiles] = useState<FileType[]>([]);

    useEffect(() => {
        if (dropzone.acceptedFiles.length !== 0)
            onUpload(dropzone.acceptedFiles)
        setUploadedFiles(dropzone.acceptedFiles);
    }, [dropzone.acceptedFiles]);

    const onDeleteUploadFile = (file: FileType) => {
        dropzone.acceptedFiles.splice(
            dropzone.acceptedFiles.indexOf(file as File),
            1,
        );
        setUploadedFiles([...dropzone.acceptedFiles]);
    };

    return (
        <Box sx={{ position: 'relative' }}>
            <UploadModern

                uploadText='Drag n drop some files here, or click to select files'
                dropzone={dropzone}
            />
            {attachments.length > 0 && <aside>
                <AppList
                    data={attachments}
                    renderRow={(file:any, index:number) => (
                        <FileRow
                            key={index}
                            file={{ name: file.fileName, path: file.fileName }}
                            onDeleteUploadFile={onDeleteUploadFile}
                        />
                    )}
                />
            </aside>}
        </Box>
    );
};

export default Basic;
