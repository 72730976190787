import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// type SettingType = {
//     navCollapsed: boolean;
//     initialPath: string | undefined;
//   }
  




const commonSlice = createSlice({
    name: 'common',
    initialState: {
        isAppDrawerOpen: false,
        message: "",
    },
    reducers: {
        showMessage(state, action: PayloadAction<string>){
            state.message = action.payload
        },
        onToggleAppDrawer(state){
            state.isAppDrawerOpen = !state.isAppDrawerOpen
        },
        hideMessage(state){
            state.message = ""
        }
    }
})


export default commonSlice.reducer

export const {showMessage, onToggleAppDrawer, hideMessage} = commonSlice.actions