import { Box, Divider, Paper, Typography } from '@mui/material'
import React from 'react'
type DetailsCardValues = {
    title: string,
    value: string | number
}
type DetailsCardProps = {
    title: string,
    values: DetailsCardValues[]
}
const CardElement = (props: { title: string, value: string | number }) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: { xs: 'row', md: 'column' }, gap: '15px', justifyContent: { xs: 'space-between' } }}>
            <Typography>{props.title}</Typography>
            <Typography fontWeight={600}>{props.value}</Typography>
        </Box>
    )
}
const DetailsCard: React.FC<DetailsCardProps> = ({ title, values }) => {
    return (
        <Box component={Paper} mb={5}
            sx={{ backgroundColor: '#fff', padding: '30px 20px 20px 30px', borderRadius: '14px', width: { xs: '100%' } }}>
            <Box mb={3}>
                <Typography variant='h4'>{title}</Typography>
            </Box>
            <Divider />
            <Box sx={{ display: 'flex', justifyContent: 'space-between ', marginTop: '25px', flexDirection: { xs: 'column', md: 'row', gap: '20px' } }}>
                {values.map((val, index) => (
                    <div key={index}>
                        <CardElement title={val.title} value={val.value} />
                    </div>
                ))}
            </Box>
            {/* <Box mt={5} display='flex' justifyContent='space-between'>
      <Box flex={1} display='flex' flexDirection={'column'} gap={'10px'}>
        <Typography>Test Name</Typography>
        <Typography>User Name</Typography>
        <Typography>Score</Typography>
        <Typography>Time Taken</Typography>
        <Typography>Number Of Attempts</Typography>
      </Box>
      <Box flex={1} display='flex' flexDirection={'column'} gap={'10px'}>
        <Typography fontWeight={600}>{testAttempt?.test.name}</Typography>
        <Typography fontWeight={600}>{testAttempt?.user.email}</Typography>
        <Typography fontWeight={600}>{testAttempt?.score.rightAnswers}</Typography>
        <Typography fontWeight={600}>{testAttempt?.timeTaken}</Typography>
        <Typography fontWeight={600}>{testAttempt?.attemptNumber}</Typography>
      </Box>
    </Box> */}
        </Box>
    )
}

export default DetailsCard