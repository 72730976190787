import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { TableCellProps } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { AppScrollbar } from "@crema";
import { ApiStatusEnum, IApiState } from "api/api";
import { LinearProgress } from "@mui/material";

export type TableColumnValue = {
  value: string | number | React.ReactElement,
  tableCell?: TableCellProps
}

export type TableRowValueType = TableColumnValue[]

export interface IRow {
  id: string | any,
  values: TableRowValueType
}

export type BasicTableProps = {
  handleEdit?: ((url: string, Id: number) => void | Promise<any>),
  rows: IRow[],
  tableHead: React.ReactNode,
  toolbar?: React.ReactNode,
  api?:IApiState
}

const BasicTable: React.FC<BasicTableProps> = (props) => {
  const { rows, tableHead, toolbar,api } = props

  return (
    <React.Fragment>
      {toolbar !== undefined && toolbar}
      <TableContainer>
        <AppScrollbar>
        <Table
            style={{
              minWidth: 750,
            }}
            aria-labelledby="tableTitle"
            size='small'
            aria-label="enhanced table"
          >
            {tableHead}
            <TableBody>
              {rows.map(row => (
                <TableRow key={row.id}>
                  {row.values.map((col, index) => (
                    <TableCell key={index} align="left" {...col.tableCell}>
                      {col.value}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </AppScrollbar>
      </TableContainer>
      {api && api.status === ApiStatusEnum.LOADING && <LinearProgress />}

    </React.Fragment>
  );
}

export default BasicTable;