import { createAsyncThunk, createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApiStatusEnum, IApiState } from "api/api";
import testAttemptApi from "api/testAttemptApi";
import { AppState } from "store/store";
import apiUtil from "util/api.util";

const testAttemptsAdapter = createEntityAdapter<TestAttemptResponseType>()

export type TestAttemptListArgsType = {
    filter: TestAttemptFilterParams
    pagination: PaginationType
}

type InitialStateType = {
   api:IApiState,
    listArgs: TestAttemptListArgsType
}

const addOnInitialState:InitialStateType = {
    api:{
        status:ApiStatusEnum.IDLE,
        error:null
    },
    listArgs: {
        
        filter: {
            asc: false,
            pageSize: 25,
            o: 'id',
        },
        pagination: {},
    }
}

export const testAttemptListThunk = createAsyncThunk(
    'testAttempt/list',
    async(_:undefined,{rejectWithValue,getState})=>{
        const args: TestAttemptListArgsType = (getState() as AppState).testAttempt.listArgs
        try {
            const response = args.pagination.next ?
            await testAttemptApi.listWithUrl(args.pagination.next) :
            await testAttemptApi.list(args.filter )
        return {
            data: response.data,
            pagination: { next: apiUtil.extractAxiosHeaderLink(response, 'next') },
            reset: args.pagination.next ? false : true
        }
        } catch (error:any) {
            return rejectWithValue(error.response.data)
        }
    }
)

// export const testAttemptGetThunk = createAsyncThunk(
//     'testAttempt/get',
//     async(_:undefined,{rejectWithValue,getState})=>{
//         const args: TestAttemptListArgsType = (getState() as AppState).testAttempt.listArgs
//         try {
//             const response = args.pagination.next ?
//             await testAttemptApi.listWithUrl(args.pagination.next) :
//             await testAttemptApi.list({ ...args.filter })
//         return {
//             data: response.data,
//             pagination: { next: apiUtil.extractAxiosHeaderLink(response, 'next') },
//             reset: args.pagination.next ? false : true
//         }
//         } catch (error:any) {
//             return rejectWithValue(error.response.data)
//         }
//     }
// )

export const testAttemptSlice = createSlice({
    name:'testAttempt',
    initialState:testAttemptsAdapter.getInitialState(addOnInitialState),
    reducers:{
        resetState(state){
            state.api = {
                status:ApiStatusEnum.IDLE
            }
            testAttemptsAdapter.removeAll(state)
        },
        setFilters(state, action: PayloadAction<Partial<TestAttemptFilterParams>>) {
            state.listArgs = {
                filter: {
                    pageSize: state.listArgs.filter.pageSize,
                    asc: state.listArgs.filter.asc,
                    o: state.listArgs.filter.o,
                    ...action.payload
                },
                pagination: {}
            }
        },
    },
    extraReducers:builder => {
        builder.addCase(testAttemptListThunk.fulfilled,(state,action)=>{
            state.api = {
                status:ApiStatusEnum.SUCCEEDED,
                error:null
            }
            state.listArgs.pagination = action.payload.pagination
            if (action.payload.reset)
            testAttemptsAdapter.removeAll(state)
            testAttemptsAdapter.upsertMany(state,action.payload.data)
        })

        builder.addCase(testAttemptListThunk.pending,(state,action)=>{
            state.api = {
                status:ApiStatusEnum.LOADING,
                error:null
            }
        })

        builder.addCase(testAttemptListThunk.rejected,(state,action)=>{
            state.api = {
                status:ApiStatusEnum.FAILED,
                error:action.payload
            }
        })
    }
})

export default testAttemptSlice.reducer

export const 
{
    resetState:resetTestAttemptState,
    setFilters:setTestAttemptsFilters,

} = testAttemptSlice.actions

export const 
{
   selectAll:selectAllTestAttempt,
   selectById:selectTestattemptById
} = testAttemptsAdapter.getSelectors((state:AppState)=>state.testAttempt)

export const selectTestAttemptsApi = (state:AppState):IApiState => state.testAttempt.api

export const selectTestAttemptsFilterParams = (state:AppState) => state.testAttempt.listArgs.filter
 
export const selectTestAttemptsPagination = (state:AppState) => state.testAttempt.listArgs.pagination