
import React, { useEffect } from 'react'
import { Outlet } from 'react-router'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { selectUserFilterParams, userListThunk } from 'store/reducers/user.reducer'

const Users = () => {
  const filterArgs = useAppSelector(selectUserFilterParams)
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(userListThunk())
}, [dispatch,filterArgs])
  return <Outlet />
}

export default Users