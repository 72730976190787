import { IconButton, InputBase, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { RefObject, useEffect, useRef, useState } from 'react'
import VerifiedIcon from '@mui/icons-material/Verified';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import InputBaseStyles from 'styles/accordion.module.scss'
import clsx from 'clsx';
type InputBaseTypeProps = {
    isAnswer?: boolean,
    edit: boolean,
    value?: string | number,
    handleSave: (value: string) => void,
    setEdit: () => void,
    setIsAnswer?: () => void,
    isChoice?: boolean,
    bgColor?: string,
    placeholder?:string,
    label?:string,
    acceptNull?:boolean

}

const InputBaseCustom: React.FC<InputBaseTypeProps> = ({ isAnswer, edit, value, setIsAnswer, setEdit, isChoice, handleSave, bgColor,placeholder,label,acceptNull}) => {
    const inputRef = useRef<HTMLInputElement>(null)
    const noFocus = useRef(null)
    // useEffect(() => {
    //     if (inputRef) {
    //         inputRef.current?.focus()
    //     }

    // }, [inputRef,edit])

    const [inputValue, setInputValue] = useState(value ?? '')
    
    return (
        <Box>
            {label && <Typography mb={1} ml={1} fontWeight={500} fontSize={'12px'}>{label}</Typography>}
        <Box
            display='flex'
            alignItems={isChoice ? 'center' : 'flex-start'}
            className={clsx(InputBaseStyles.inputBaseCustom)}
            sx={{ padding: '10px', gap: '10px', borderRadius: '8px', backgroundColor: `${bgColor}` }}
        >
            {isChoice  && <IconButton sx={{cursor:setIsAnswer!== undefined ? "pointer":'not-allowed'}}  onClick={() => {setIsAnswer && setIsAnswer()}} size='small' style={{ padding: 0 }}>
                <VerifiedIcon color={isAnswer ? 'success' : 'disabled'} />
            </IconButton>}
            <InputBase
            placeholder={placeholder}
            inputRef={edit?inputRef:noFocus}
                multiline={!isChoice}
                fullWidth
                disabled={!edit}
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
            />

            {edit ? (
                <IconButton disabled={(inputValue === value || (inputValue === ''&& !acceptNull))} onClick={() => {
                    handleSave(inputValue.toString())
                    // setInputValue(value ?? '')
                }} size='small' style={{ padding: 0 }}>
                    <SaveIcon />
                </IconButton>
            ) : (
                <Box className={clsx(InputBaseStyles.endButtonBox)} display={isChoice ? 'none' : 'block'}>

                    <IconButton onClick={() => {
                        inputRef?.current?.focus()
                        setEdit()
                    }} size='small' style={{ padding: 0 }}>
                        <EditIcon />
                    </IconButton>
                </Box>

            )}

        </Box>
        </Box>
    )
}

export default InputBaseCustom