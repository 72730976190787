import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { ApiStatusEnum, IApiState, UploadProgessFnType } from "api/api";
import choiceApi from "api/choiceApi";
import questionApi from "api/questionApi";
import { AppState } from "store/store";
import apiUtil from "util/api.util";

const questionAdapter = createEntityAdapter<SeruQuestionListType>({
    // sortComparer: (a, b) => a.position.toString().localeCompare(b.position.toString())
    selectId:obj => obj.url
})

type QuestionStateType = {
    api: IApiState,
}



export type QuestionWithPostionType = SeruQuestionListType & { position: number ,isTrial:boolean}

const addOnInitialState: QuestionStateType & {pagination:PaginationType} = {
    api: {
        status: ApiStatusEnum.IDLE,
        error: null
    },
        pagination: {},
}

export const questionsListThunk = createAsyncThunk(
    'question/list',
    async (payload: string, { rejectWithValue, dispatch }) => {
        try {
            const response = await questionApi.listQuestions(payload)
            return {
                data:response.data,
                pagination: { 
                    next: apiUtil.extractAxiosHeaderLink(response, 'next') ,
                    current: apiUtil.extractAxiosHeaderLink(response, 'current') ,
                },
            }
        } catch (error: any) {
            return rejectWithValue(error.response.data)
        }

    }
)
export const questionsUpdateThunk = createAsyncThunk(
    'questions/update',
    async (payload: { data: Partial<QuestionsCreateRequestType>, questionId: number, testId: number }, { rejectWithValue, dispatch }) => {
        try {
            const response = await questionApi.updateQuestion({ testId: payload.testId, questionId: payload.questionId }, payload.data)
            return response.data
        } catch (error: any) {
            return rejectWithValue(error.response.data)
        }
    }

)
export const questionsSetTrialThunk = createAsyncThunk(
    'questions/setTrial',
    async (payload: { url: string, isTrial: boolean }, { rejectWithValue }) => {
        try {
            const response = await questionApi.setTrial(payload)
            return response.data
        } catch (error: any) {
            return rejectWithValue(error.response.data)
        }
    }

)

export const questionsCreateThunk = createAsyncThunk(
    'questions/create',
    async (payload: { data: QuestionsCreateRequestType }, { rejectWithValue }) => {
        try {
            const response = await questionApi.createQuestion(payload.data)
            return response.data
        } catch (error: any) {
            return rejectWithValue(error.response.data)
        }
    }

)

export const questionsDeleteThunk = createAsyncThunk(
    'questions/delete',
    async (payload: { test: string, id: number }, { rejectWithValue }) => {
        try {
            const response = await questionApi.deleteQuestion(payload.id,payload.test)
            return { id: payload.id, positions: response.data.positions }
        } catch (error: any) {
            return rejectWithValue(error.response.data)
        }
    }

)


export const excelImportThunk = createAsyncThunk(
    'questions/excel/import',
    async (payload: { testId: number, data: File,progressFn:UploadProgessFnType }, { rejectWithValue }) => {
        try {
            await questionApi.excelImport(payload.testId,payload.data,payload.progressFn)
        } catch (error: any) {
            return rejectWithValue(error.response.data)
        }
    }
)


const questionSlice = createSlice({
    name: 'question',
    initialState: questionAdapter.getInitialState(addOnInitialState),
    reducers: {
        resetState(state) {
            state.api = {
                status: ApiStatusEnum.IDLE
            }
            state.pagination = {}
            questionAdapter.removeAll(state)
        },
    },
    extraReducers: builder => {
        builder.addCase(questionsListThunk.fulfilled, (state, action) => {
            state.api = {
                status: ApiStatusEnum.SUCCEEDED,
                error: null
            }
            questionAdapter.addMany(state, action.payload.data)
            state.pagination = action.payload.pagination
        })

        builder.addCase(questionsUpdateThunk.fulfilled, (state, action) => {
            state.api = {
                status: ApiStatusEnum.SUCCEEDED,
                error: null
            }

            questionAdapter.upsertOne(state, action.payload)
        })
        builder.addCase(questionsCreateThunk.fulfilled, (state, action) => {
            state.api = {
                status: ApiStatusEnum.SUCCEEDED,
                error: null
            }
            questionAdapter.upsertOne(state, action.payload)
        })
        builder.addCase(questionsDeleteThunk.fulfilled, (state, action) => {
            state.api = {
                status: ApiStatusEnum.SUCCEEDED,
                error: null
            }
            questionAdapter.removeOne(state, action.payload.id)
        })
        builder.addCase(excelImportThunk.fulfilled, (state) => {
            state.api = {
                status: ApiStatusEnum.SUCCEEDED
            }
        })
        builder.addMatcher(isAnyOf(
            questionsListThunk.pending,
            questionsUpdateThunk.pending,
            questionsCreateThunk.pending,
            questionsDeleteThunk.pending,
            questionsSetTrialThunk.pending,
            excelImportThunk.pending
        ), (state) => {
            state.api = {
                status: ApiStatusEnum.LOADING,
                error: null
            }
        })
        builder.addMatcher(isAnyOf(
            questionsListThunk.rejected,
            questionsUpdateThunk.rejected,
            questionsUpdateThunk.rejected,
            questionsDeleteThunk.rejected,
            questionsSetTrialThunk.rejected,
            excelImportThunk.rejected
        ), (state, action) => {
            state.api = {
                status: ApiStatusEnum.FAILED,
                error: action.payload
            }
        })
    }

})



export default questionSlice.reducer

export const { resetState: resetQuestionState } = questionSlice.actions

export const
    {
        selectAll: selectAllQuestion
    } = questionAdapter.getSelectors((state: AppState) => state.question)

export const selectPositionEnities = (state: AppState) => state.question.entities

export const selectSortedQuestions = createSelector(
    [(state:AppState,testUrl:string) => testUrl, selectAllQuestion],
    (testUrl, questions) => {
        const questionsWithPos:QuestionWithPostionType[] = questions.map(e => {
            const test= e.tests.find(e => e.test === testUrl)
            return {...e,position:test?.position ?? 0,isTrial:test?.isTrial ?? false}
        })

        return questionsWithPos.sort((a, b) => a.position - b.position)
    }
)

export const selectQuestionApi = (state: AppState) => state.question.api

export const selectQuestionPagination = (state: AppState) => state.question.pagination