import { apiInstance } from "api/client";

const getUserList = async (params: UserFilterParams) =>
    apiInstance.get<UserResponseType[]>(`/user/q-admin/`, { cvtResToCamel: true, params: params, cvtReqParamsToSnake: true })

const getUserProfileListByAdmin = async (params: UserFilterParams) =>
    apiInstance.get<QAdminUserResponseType[]>(`/profile/admin/`, { cvtResToCamel: true, params: params, cvtReqParamsToSnake: true })

const firebaseUserSync = async (id: string) =>
    apiInstance.post<UserResponseType>(`/user/q-admin/${id}/firebase-sync/`, {}, { cvtResToCamel: true, cvtReqParamsToSnake: true })

const getUserListWithUrl = async (url: string) =>
    apiInstance.get<QAdminUserResponseType[]>(url, { cvtResToCamel: true })

const getPaymentInfo = async (id: string) =>
    apiInstance.get(`/payment/admin/`, { cvtResToCamel: true, params: { user: id } })

// {base_url}}





const userApi = {
    getUserList,
    getUserListWithUrl,
    firebaseUserSync,
    getUserProfileListByAdmin,
    getPaymentInfo
}

export default userApi