import { Box, Button, Divider, FormControlLabel, MenuItem, Switch, Typography } from '@mui/material'
import { unwrapResult } from '@reduxjs/toolkit'
import DialogWithClose from 'components/Dialog/DialogWithClose'
import FormikTextField from 'components/FormikTextField'
import { Form, Formik } from 'formik'
import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { selectAllTest, testAutoCreateThunk, testListThunk } from 'store/reducers/test.reducer'
import * as yup from 'yup'
interface Props {
    open: boolean,
    handleClose: () => void,
    testId: number
}

type AutoCreateFormType = {
    test: string,
    noOfQuestions: string,
    allowDuplicate: string
}
const AutoCreateForm: React.FC<Props> = ({ handleClose, open, testId }) => {
    const [error, setError] = useState<any>(undefined)

    const tests = useAppSelector(selectAllTest)
    const dispatch = useAppDispatch()

    const initialvalues: AutoCreateFormType = {
        test: '',
        noOfQuestions: '',
        allowDuplicate: 'True',
    }



    const validationSchema = yup.object({
        test: yup.string().url('Attachment must be a valid URL'),
        noOfQuestions: yup.number(),
        allowDuplicate: yup.string(),
    })
    return (
        <DialogWithClose maxWidth={'md'} title={"Auto Create"} open={open} onClose={handleClose}>
            <Formik
                initialValues={initialvalues}
                validationSchema={validationSchema}
                enableReinitialize
                onSubmit={(values, helpers) => {
                    dispatch(testAutoCreateThunk({ id: testId, data: { ...values, noOfQuestions: parseInt(values.noOfQuestions) } }))
                        .then(unwrapResult)
                        .then(() => {
                            dispatch(testListThunk())
                            handleClose()
                        })
                        .catch(err => {
                            setError(err)
                        })
                        .finally(() => {
                            helpers.setSubmitting(false)
                        })
                }}
            >
                {
                    ({ isSubmitting, dirty, setFieldValue, values }) => {
                        return (
                            <Form>
                                <Box sx={{ width: '600px', pt: 2 }} display={'flex'} flexDirection={'column'} gap={4}>
                                    <FormikTextField
                                        label="Test"
                                        name='test'
                                        fullWidth
                                        select
                                    >
                                        <MenuItem value={''}>Select</MenuItem>
                                        {tests.filter(e => e.id !== testId).map((item, index) => <MenuItem key={index} value={item.url}>{item.name}</MenuItem>)}
                                    </FormikTextField>
                                    <FormikTextField
                                        type='number'
                                        label="No Of Questions"
                                        name='noOfQuestions'
                                        fullWidth
                                    />

                                    <FormControlLabel
                                        sx={{ width: 'fit-content', px: 0 }}
                                        control={
                                            <Switch
                                                checked={values.allowDuplicate === 'True'}
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        setFieldValue('allowDuplicate', "True")
                                                    } else {
                                                        setFieldValue('allowDuplicate', "False")
                                                    }
                                                }}
                                            />
                                        } label="Allow Duplicate" labelPlacement='start' />
                                        <Box display={'flex'} gap={2}>
                                    <Button type='submit' disabled={isSubmitting || !dirty} variant='contained' sx={{ width: 'fit-content' }}>
                                        Save
                                    </Button>
                                        {error?.detail && <Typography color={'red'}>*{error?.detail}</Typography>}
                                        </Box>

                                    
                                </Box>
                            </Form>
                        )
                    }
                }
            </Formik>
        </DialogWithClose >
    )
}

export default AutoCreateForm