import { configureStore, } from '@reduxjs/toolkit';
import authReducer from './reducers/auth.reducer';
import commonReducer from './reducers/common.reducer';
import publicUserReducer from './reducers/user.reducer';
import questionReducer from './reducers/question.reducer';
import settingReducer from './reducers/setting.reducer';
import testReducer from './reducers/test.reducer';
import testAttemptReducer from './reducers/testAttempt.reducer';
import listAnswerReducer from './reducers/listAnswer.reducer';
import dynamicConfigReducer from './reducers/dynamicConfig.reducer';






export const store = configureStore({
  reducer: {
    auth:authReducer,
    settings: settingReducer,
    common: commonReducer,
    test:testReducer,
    question:questionReducer,
    testAttempt:testAttemptReducer,
    user:publicUserReducer,
    listAnswer:listAnswerReducer,
    config:dynamicConfigReducer
  },
 
});

export type AppDispatch = typeof store.dispatch;
export type AppState = ReturnType<typeof store.getState>;

// export const history = createReduxHistory(store);


// export type AppThunk<ReturnType = void> = ThunkAction<
//   ReturnType,
//   RootState,
//   unknown,
//   Action<string>
// >;


