import { UploadProgessFnType } from 'api/api'
import { apiInstance } from 'api/client'
import snakecaseKeys from 'snakecase-keys'

const listQuestions = async (url: string) =>
    apiInstance.get<SeruQuestionListType[]>(url, { cvtResToCamel: true })

// const listQuestionsWithUrl = async (url: string) =>
// apiInstance.get<QuestionListResponseType>(url, { cvtResToCamel: true })

const getQuestion = async (urlParams: QuestionUrlParamsType) =>
    apiInstance.get<SeruQuestionListType>(`/test/q-admin/${urlParams.testId}/question/${urlParams.questionId}`)

const updateQuestion = async (urlParams: QuestionUrlParamsType, data: Partial<QuestionsCreateRequestType>) => {





    return apiInstance.patch<SeruQuestionListType>(`/main/q-admin/question/${urlParams.questionId}/`,
        snakecaseKeys(data),
        {
            cvtResToCamel: true,
            // headers: { 'Content-Type': 'multipart/form-data' },
            headers: { "Content-Type": "application/json" },
        }
    )
}

const createQuestion = async (data: QuestionsCreateRequestType) => {
    // const formData = new FormData()
    // formData.append('question', data.question)
    // if (data.file)
    //     formData.append('file', data.file)
    // if (data.videoUrl)
    //     formData.append('video_url', data.videoUrl)
    return apiInstance.post<SeruQuestionListType>(`/main/q-admin/question/`,
        snakecaseKeys(data),
        {
            cvtResToCamel: true,
            cvtReqParamsToSnake: true
        }
    )
}

const deleteQuestion = async (question: number, test: string) =>
    apiInstance.post(`/main/q-admin/question/${question}/remove-from-test/`, { test })

const setAnswer = async (urlParams: ChoiceUrlParamsType, isUnSet: boolean) =>
    apiInstance.patch<ChoiceResponseType[]>(`/test/q-admin/${urlParams.testId}/question/${urlParams.questionId}/${isUnSet ? 'unset-answer' : 'set-answer'}/`, { choice: urlParams.choiceId }, { cvtResToCamel: true, cvtReqBodyToSnake: true })

const setTrial = async (payload: { url: string, isTrial: boolean }) =>
    apiInstance.patch<QuestionResponseType>(`${payload.url}set-trial/`, { isTrial: payload.isTrial }, { cvtResToCamel: true, cvtReqBodyToSnake: true })

const excelImport = async (testId: number, data: File, progressFn: UploadProgessFnType) => {
    const formData = new FormData()
    formData.append('file', data)
    return apiInstance.post(`main/q-admin/${testId}/upload/`,
        formData,
        {
            cvtResToCamel: true,
            headers: { 'Content-Type': 'multipart/form-data' },
            onUploadProgress: progressFn
        }
    )
}





const questionApi = {
    listQuestions,
    getQuestion,
    updateQuestion,
    createQuestion,
    deleteQuestion,
    setAnswer,
    setTrial,
    excelImport,
}

export default questionApi