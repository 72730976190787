
import { Button, LinearProgress, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { UploadProgessFnType } from 'api/api'
import React, { useState } from 'react'
import Dropzone from 'react-dropzone'
type DropZoneProps = {
    handleSubmit: (acceptedFiles: File, progressFn: UploadProgessFnType) => void,
    handleClose: () => void,
    error?: string
}

const CustomDropZone = (props: DropZoneProps) => {
    const [file, setFile] = useState<File>()
    const [progress, setProgress] = useState<number>()

    const { handleSubmit, handleClose } = props

    return (
        <Box style=
            {{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '30px',
            }}>
            <Box sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center'

            }}>
                <Typography>Upload File</Typography>
            </Box>
            <Box sx={{

                border: 'dashed',
                width: '100%',
                height: '200px'
            }}>
                <Dropzone
                    onDrop={acceptedFiles => {
                        setFile(acceptedFiles[0])
                    }}
                    maxFiles={1}


                >
                    {({ getRootProps, getInputProps }) => (
                        <Box {...getRootProps()} >
                            <input {...getInputProps()} />
                            <Box sx={{
                                padding: '10px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center', width: '100%', height: '200px'
                            }}>
                                <p >Drag 'n' drop some files or click </p>
                            </Box>
                        </Box>
                    )}
                </Dropzone>
            </Box>
            {file && <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                width: '100%'
            }}>
                <Box >
                    {/* <img style={{ width: '150px', height: '75px' }} src={URL.createObjectURL(file)} alt="" /> */}
                    <Typography variant='body1'>File : {file.name}</Typography>
                    {props.error && <Typography sx={{color:'red',marginTop:'5px'}} variant='body1'>{props.error}</Typography>}
                </Box>
            </Box>}
            {progress && (<Box sx={{
                width: '100%',
            }}>
                <LinearProgress color="primary" variant="determinate" value={progress * 100} />
            </Box>)}
            <Box sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center'
            }}>
                <Button
                    onClick={handleClose}
                    variant='text'>Cancel</Button>
                <Button
                    disabled={file === undefined}
                    onClick={() => file && handleSubmit(file, (progressEvent) => {
                        setProgress(progressEvent.progress)
                    })}
                    variant='text'>Submit</Button>
            </Box>
        </Box>
    )
}

export default CustomDropZone