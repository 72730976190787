import PaginatedTableHead from 'components/Tables/PaginatedTable/PaginatedTableHead'
import { PaginatedHeadCell, PaginatedOrder } from 'components/Tables/PaginatedTable/types'
import React from 'react'

export type TestAttemptsTableDataType = Partial<TestAttemptResponseType>

const headCells: PaginatedHeadCell<TestAttemptsTableDataType >[] = [
    { id: 'id', align: 'left', disablePadding: false, label: 'Id', hasSorting: true },
    { id: 'test', align: 'left', disablePadding: false, label: 'Test Name', hasSorting: false },
    { id: 'user', align: 'left', disablePadding: false, label: 'User Name', hasSorting: false },
    { id: 'createAt', align: 'left', disablePadding: false, label: 'Test Type', hasSorting: false },
    { id: 'rightAnswerCount', align: 'left', disablePadding: false, label: 'Right Answer', hasSorting: false },
    { id: 'totalAnswerCount', align: 'left', disablePadding: false, label: 'Total Answer', hasSorting: false },
    { id: 'timeTaken', align: 'left', disablePadding: false, label: 'Time Taken', hasSorting: false },
  ]

  interface TestAttemptsTableHeadProps {
    onRequestSort: (property: keyof TestAttemptsTableDataType) => void;
    order: PaginatedOrder;
    orderBy: keyof TestAttemptsTableDataType;
  }
const AttemptsTableHead = (props:TestAttemptsTableHeadProps) => {
    const { order, orderBy, onRequestSort } = props;
  return (
    <PaginatedTableHead 
    headCells={headCells} 
    orderBy={orderBy} 
    order={order} 
    sortHandler={onRequestSort} 
    />
  )
}

export default AttemptsTableHead