import { AppGridContainer } from "@crema";
import { Box, Grid, Paper, useTheme } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import PaginatedTable, { PaginatedTableRowType } from "components/Tables/PaginatedTable";
import { CremaTheme } from "@cremaMods/types/AppContextPropsType";
import { useAppDispatch, useAppSelector } from "store/hooks";
import AttemptsTableHead, { TestAttemptsTableDataType } from "./AttemptsTableHead";
import { 
  selectAllTestAttempt, 
  selectTestAttemptsApi, 
  selectTestAttemptsFilterParams, 
  selectTestAttemptsPagination, 
  setTestAttemptsFilters, 
  testAttemptListThunk } from "store/reducers/testAttempt.reducer";
import AttemptsToolBar from "./AttemptsToolBar";
import { selectAllTest, testListThunk } from "store/reducers/test.reducer";

type AttemptsTableProps = {
  userId?:string
}

type TestAttemptTableRowType = PaginatedTableRowType<TestAttemptsTableDataType>

function createAttemptsRow(data: {
  attempt: TestAttemptResponseType,
  theme: CremaTheme,
  onClick:(id:number) => void,
}): TestAttemptTableRowType {
  return {
    id: data.attempt.id,
    values: {
      'id': {
        value: (<Box sx={{
          color: data.theme.palette.primary.main,
          cursor: 'pointer',
          textDecoration: 'none',
          '&:hover': {
            color: data.theme.palette.secondary.main
          }
        }} onClick={() => {data.onClick(data.attempt.id) }}>{`#${data.attempt.id}`}</Box>),
        tableCell: { component: 'th', scope: 'row' }
      },
      'test': { value: data.attempt.test.name },
      'user': { value: data.attempt.user.email ?? '' },
      'attemptType': { value: data.attempt.attemptType },
      'rightAnswerCount': { value: data.attempt.rightAnswerCount },
      'totalAnswerCount': { value: data.attempt.totalAnswerCount },
      'timeTaken': { value: data.attempt.timeTaken },
    }
  }
}

export const getAttemptRows = (
  testAttempts: TestAttemptResponseType[],
  filterArgs: TestAttemptFilterParams,
  currentPage: number,
  theme: CremaTheme,
  onClick: (id:number) => void,
): TestAttemptTableRowType[] =>
  testAttempts
    .slice(currentPage * filterArgs.pageSize, currentPage * filterArgs.pageSize + filterArgs.pageSize)
    .map(attempt => createAttemptsRow({ attempt: attempt, theme,onClick }))




const AttemptsTable:React.FC<AttemptsTableProps> = ({userId}) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const filterArgs = useAppSelector(selectTestAttemptsFilterParams)

  useEffect(() => {
    dispatch(testListThunk())
    }, [dispatch])
   
    useEffect(() => {
      if(userId===undefined)
     { 
      dispatch(setTestAttemptsFilters({}))
    }
      }, [userId])
    
  const theme = useTheme<CremaTheme>()

  const [currentPage, setCurrentPage] = useState(0)
  const [maxPage, setMaxPage] = useState(0)


  const handleDialogEdit = useCallback((id:number) => {
    navigate(`/dashboard/attempts/view/${id}`)
  }, [navigate])
  const tests = useAppSelector(selectAllTest)
  const testAttempts = useAppSelector(selectAllTestAttempt)
  const pagination = useAppSelector(selectTestAttemptsPagination)
  const rows = useMemo(() => getAttemptRows(testAttempts, filterArgs, currentPage, theme, handleDialogEdit), [testAttempts, filterArgs, currentPage, theme, handleDialogEdit])
  const api = useAppSelector(selectTestAttemptsApi)
  const resetPages = () => {
    setCurrentPage(0)
    setMaxPage(0)
  }

  const handleRequestSort = (property: keyof TestAttemptsTableDataType) => {
    const isAsc = filterArgs.o === property && filterArgs.asc;
    const orderByChoices: (keyof TestAttemptResponseType)[] = ['id']
    const orderByIndex = orderByChoices.findIndex(e => e === property)
    resetPages()
    dispatch(setTestAttemptsFilters({
      asc: isAsc ? false : true,
      o: orderByChoices[orderByIndex],
      user:userId 
    }))
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    if (newPage > maxPage) {
      setMaxPage(newPage)
      await dispatch(testAttemptListThunk())
    }
    setCurrentPage(newPage)
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setTestAttemptsFilters({ pageSize: parseInt(event.target.value, 10),user:userId }))
    dispatch(testAttemptListThunk())
    resetPages()
  };

  const changeFilter = (filters: Partial<TestAttemptFilterParams>) => {
    dispatch(setTestAttemptsFilters({...filters,user:userId}))
  }
  return (
    <AppGridContainer>
      <Grid item xs={12}>
        <Paper>
          <PaginatedTable
            rows={rows}
            tableHead={
              <AttemptsTableHead
                order={filterArgs.asc ? 'asc' : 'desc'}
                orderBy={filterArgs.o}
                onRequestSort={handleRequestSort} />
            }
            toolbar={<AttemptsToolBar changeFilter={changeFilter} api={api} tests={tests} />}
            api={api}
            pagination={{
              rowsPerPage: filterArgs.pageSize,
              currentPage: currentPage,
              nextIconDisabled: testAttempts.length > 0 && !pagination.next && currentPage === maxPage ? true : false,
              handleChangePage: handleChangePage,
              handleChangeRowsPerPage: handleChangeRowsPerPage
            }}
          />
        </Paper>
      </Grid>
    </AppGridContainer>
  );
};

export default AttemptsTable;