
import Unauthorized from "containers/Unauthorized";
import LoginForm from "containers/Unauthorized/Login/LoginForm";
import { DetailedRouteConfig } from "routes/routes.typing";


const routes: DetailedRouteConfig = {
    path:"/",
    fallbackPath: "/dashboard",
    element: <Unauthorized/>,
    children: [
        {
            path: "/login",
            element: <LoginForm />,
        }
        
    ]
}

export default routes