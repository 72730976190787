// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getAuth} from 'firebase/auth'
// import { getStorage } from "firebase/storage";
// import { getFirestore } from "firebase/firestore";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCejFXaiid3e-ni1Vakq8h0BEBNoiC3tpM",
  authDomain: "seru-training.firebaseapp.com",
  projectId: "seru-training",
  storageBucket: "seru-training.appspot.com",
  messagingSenderId: "528307961601",
  appId: "1:528307961601:web:09de703c7467aa6f862500",
  measurementId: "G-PXYRTMZT1Y"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth()
// export const storage = getStorage();
// export const db = getFirestore()
// const analytics = getAnalytics(app);