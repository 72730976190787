import { AppGridContainer } from "@crema";
import { Alert, Box, Grid, Paper, Snackbar, useTheme } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import PaginatedTable, { PaginatedTableRowType } from "components/Tables/PaginatedTable";
import { CremaTheme } from "@cremaMods/types/AppContextPropsType";
import { useAppDispatch, useAppSelector } from "store/hooks";

import { PaginatedHeadCell } from "components/Tables/PaginatedTable/types";
import { selectAllUser, selectUserApi, selectUserFilterParams, selectUserPagination, setUserFilters, userListThunk, userSyncThunk } from "store/reducers/user.reducer";
import BasicTableHead from "components/Tables/BasicTable/BasicTableHead";
import { useNavigate } from "react-router";
import { setTestAttemptsFilters } from "store/reducers/testAttempt.reducer";
import UsersTableToolbar from "./UsersTableToolbar";
import { RiRefreshLine } from "react-icons/ri";
import { unwrapResult } from "@reduxjs/toolkit";




export type UserTableDataType = Partial<UserResponseType> & { sync: any, dateOfBirth: string, gender: string }

const headCells: PaginatedHeadCell<UserTableDataType>[] = [
  { id: 'id', align: 'left', disablePadding: false, label: 'Sl No', hasSorting: false },
  { id: 'displayName', align: 'left', disablePadding: false, label: 'Name', hasSorting: false },
  { id: 'email', align: 'left', disablePadding: false, label: 'Email', hasSorting: false },
  // { id: 'phoneNumber', align: 'left', disablePadding: false, label: 'Phone', hasSorting: false },
  { id: 'isStaff', align: 'left', disablePadding: false, label: ' Is Staff', hasSorting: false },
  { id: 'isSuperuser', align: 'left', disablePadding: false, label: 'Is Super User', hasSorting: false },
  { id: 'dateOfBirth', align: 'left', disablePadding: false, label: 'DOB', hasSorting: false },
  { id: 'gender', align: 'left', disablePadding: false, label: 'Gender', hasSorting: false },
  { id: 'sync', align: 'left', disablePadding: false, label: 'Sync', hasSorting: false },
]

type UserTableRowType = PaginatedTableRowType<UserTableDataType & { slNo: number }>

function createUsersRow(data: {
  user: QAdminUserResponseType,
  theme: CremaTheme,
  index: number,
  onClick: (user: UserResponseType) => void,
  handleSync: (user: UserResponseType) => void,
}): UserTableRowType {
  return {
    id: data.user.user.id,
    values: {
      'slNo': {
        value: (<Box sx={{
          color: data.theme.palette.primary.main,
          cursor: 'pointer',
          textDecoration: 'none',
          '&:hover': {
            color: data.theme.palette.secondary.main
          }
        }} onClick={() => { data.onClick(data.user.user) }}>{`#${data.index}`}</Box>),
        tableCell: { component: 'th', scope: 'row' }
      },
      'displayName': { value: data.user.user.displayName ?? "---" },
      'email': { value: data.user.user.email ?? 'N/A' },
      // 'phoneNumber':{value:data.user.phoneNumber ?? 'N/A'},
      'isStaff': { value: data.user.user.isStaff ? 'YES' : "NO" },
      'isSuperuser': { value: data.user.user.isSuperuser ? 'YES' : "NO" },
      'dateOfBirth': { value: data.user.dateOfBirth ?? "---" },
      'gender': { value: data.user.gender ?? "---" },
      'sync': {
        value: <RiRefreshLine onClick={() => {
          data.handleSync(data.user.user)
        }} style={{ cursor: 'pointer' }} size={18} />

      },
    }
  }
}

export const getUsersRows = (
  users: QAdminUserResponseType[],
  filterArgs: UserFilterParams,
  currentPage: number,
  theme: CremaTheme,
  onClick: (user: UserResponseType) => void,
  handleSync: (user: UserResponseType) => void,
): UserTableRowType[] =>
  users
    .slice(currentPage * filterArgs.pageSize, currentPage * filterArgs.pageSize + filterArgs.pageSize)
    .map((user, index) => createUsersRow({ user: user, theme, index: index + 1, onClick, handleSync }))




const UsersTable = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const theme = useTheme<CremaTheme>()

  const [currentPage, setCurrentPage] = useState(0)
  const [maxPage, setMaxPage] = useState(0)


  const filterArgs = useAppSelector(selectUserFilterParams)
  const [snackBarStatus, setSnackBarStatus] = useState<{ open: boolean, message?: string, status?: 'success' | 'fail' }>({ open: false })

  const handleSync = useCallback((user: UserResponseType) => {
    dispatch(userSyncThunk(user.id))
      .then(unwrapResult)
      .then(() => {
        setSnackBarStatus({
          open: true,
          message: "Sync successful!",
          status:'success'
        })
      }
      )
      .catch(() => {
        setSnackBarStatus({
          open: true,
          message: "Sync failed!",
          status:'fail'
        })
      })
  }, [])

  const handleDialogEdit = useCallback((user: UserResponseType) => {
    navigate(`/dashboard/users/${user.id}`)
    dispatch(setTestAttemptsFilters({ user: user.id }))
  }, [navigate, dispatch])
  const users = useAppSelector(selectAllUser)

  const pagination = useAppSelector(selectUserPagination)
  const rows = useMemo(() => getUsersRows(users, filterArgs, currentPage, theme, handleDialogEdit, handleSync), [users, filterArgs, currentPage, theme, handleDialogEdit, handleSync])
  const api = useAppSelector(selectUserApi)
  const resetPages = () => {
    setCurrentPage(0)
    setMaxPage(0)
  }

  // const handleRequestSort = (property: keyof TestAttemptsTableDataType) => {
  //   const isAsc = filterArgs.orderBy === property && filterArgs.asc;
  //   const orderByChoices: (keyof TestAttemptResponseType)[] = ['id']
  //   const orderByIndex = orderByChoices.findIndex(e => e === property)
  //   resetPages()
  //   dispatch(setTestAttemptsFilters({
  //     asc: isAsc ? false : true,
  //     orderBy: orderByChoices[orderByIndex]
  //   }))
  // };

  const handleChangePage = async (event: unknown, newPage: number) => {
    if (newPage > maxPage) {
      setMaxPage(newPage)
      await dispatch(userListThunk())
    }
    setCurrentPage(newPage)
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setUserFilters({ pageSize: parseInt(event.target.value, 10) }))
    dispatch(userListThunk())
    resetPages()
  };

  const changeFilter = (filters: Partial<UserFilterParams>) => {
    dispatch(setUserFilters(filters))
  }
  return (
    <AppGridContainer>
      <Grid item xs={12}>
        <Paper>
          <PaginatedTable
            tableHead={<BasicTableHead headCell={headCells} />}
            rows={rows}
            // tableHead={
            //   <AttemptsTableHead
            //   order={filterArgs.asc ? 'asc' : 'desc'}
            //   orderBy='id' 
            //   onRequestSort={handleRequestSort} />
            // }
            toolbar={<UsersTableToolbar changeFilter={changeFilter} />}

            api={api}
            pagination={{
              rowsPerPage: filterArgs.pageSize,
              currentPage: currentPage,
              nextIconDisabled:   users.length > 0 && !pagination.next && currentPage === maxPage ? true : false,
            
              handleChangePage: handleChangePage,
              handleChangeRowsPerPage: handleChangeRowsPerPage
            }}
          />
        </Paper>
      </Grid>
      {
        snackBarStatus.open && <Snackbar open={snackBarStatus.open} autoHideDuration={6000} onClose={() => setSnackBarStatus({ open: false })}>
          <Alert
            severity={snackBarStatus.status === 'success' ? 'success' : 'error'}
            style={{ backgroundColor: snackBarStatus.status === 'success' ? 'green' : 'red', color: '#fff', width: '100%' }}
            onClose={() => setSnackBarStatus({ open: false })}
          >
            {snackBarStatus.message ?? ''}
          </Alert>
        </Snackbar>
      }
    </AppGridContainer>
  );
};

export default UsersTable;
