import { Box, Button, Divider, Typography } from '@mui/material'
import { unwrapResult } from '@reduxjs/toolkit'
import testsApi, { fileUploadApi } from 'api/testsApi'
import FormikTextField from 'components/FormikTextField'
import Basic from 'components/ReactDropzone/Basic'
import FileRow from 'components/ReactDropzone/components/FileRow'
import { Form, Formik } from 'formik'
import React from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { selectTestById, testCreateThunk, testUpdateThunk } from 'store/reducers/test.reducer'
import * as yup from 'yup'
type TransactionFormType = {
    name: string,
    description: string,
    cover: Partial<FileUploadResponseType>,
}

type Props = {
    id?: number,
    hanldeClose: () => void
}
const TestForm: React.FC<Props> = ({ id, hanldeClose }) => {
    const test = useAppSelector(state => selectTestById(state, id ?? ""))
    const dispatch = useAppDispatch()
    const initialvalues: TransactionFormType = {
        name: test?.name ? test.name : "",
        description: test?.description ? test.description : '',
        cover: test?.cover ? test.cover : {},
    }

    const attachmentsSchema = yup.object({
        url: yup.string().url('Attachment must be a valid URL'),
        file: yup.string(),
        fileName: yup.string(),
        createdAt: yup.string()
    })

    const validationSchema = yup.object({
        name: yup.string().required(),
        description: yup.string().required(),
    })
    return (
        <Formik
            initialValues={initialvalues}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={(values, helpers) => {
                if (id) {
                    dispatch(testUpdateThunk({data:{ ...values, cover: values?.cover?.url },id}))
                    .then(unwrapResult)
                    .then(() => {
                        hanldeClose()
                    })
                    .finally(() => {
                        helpers.setSubmitting(false)
                    })
                } else {
                    dispatch(testCreateThunk({ ...values, cover: values?.cover?.url }))
                        .then(unwrapResult)
                        .then(() => {
                            hanldeClose()
                        })
                        .finally(() => {
                            helpers.setSubmitting(false)
                        })
                }

            }}
        >
            {
                ({ isSubmitting, dirty, setFieldValue, values }) => {
                    return (
                        <Form>
                            <Box sx={{ width: '600px', pt: 2 }} display={'flex'} flexDirection={'column'} gap={4}>
                                <FormikTextField
                                    label="Name"
                                    name='name'
                                    fullWidth
                                />
                                <FormikTextField
                                    label="Description"
                                    name='description'
                                    fullWidth
                                />
                                <Box>
                                    <Typography>
                                        Add Cover Image
                                    </Typography>
                                    <Divider sx={{ my: 2 }} />
                                    <Basic
                                        attachments={values.cover && values.cover.url !== undefined ? [values.cover] : []}
                                        onDelete={() => { }}
                                        onUpload={async (file) => {
                                            const response = await fileUploadApi.create(file[0])
                                            setFieldValue('cover', response.data)
                                        }}
                                    />

                                </Box>

                                <Button type='submit' disabled={isSubmitting || !dirty} variant='contained' sx={{ width: 'fit-content' }}>
                                    Save
                                </Button>
                            </Box>
                        </Form>
                    )
                }
            }
        </Formik>
    )
}

export default TestForm