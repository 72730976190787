import React, { useState } from 'react'
import { Form, Formik } from 'formik';
import * as yup from 'yup'
import { IApiState } from 'api/api';
import PaginatedToolbar from 'components/Tables/PaginatedTable/PaginatedToolbar';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import AppTextField from '@crema/core/AppFormComponents/AppTextField';
import { apiDateFormat, defaultDateFormat } from 'shared/appConstants';
import { format as dateFormat } from 'date-fns'
import FormikDateField from 'components/Fields/FormikDateField';
import { MenuItem } from '@mui/material';



interface EnhancedTableToolbarProps {
    changeFilter: (filters: Partial<TestAttemptFilterParams>) => void,
    api?: IApiState,
    action?: React.ReactNode,
    tests: TestResponseType[],
}




export type TestAttemptsFilterFormType = {
    test: string,
    user: string,
    createdAtAfter: Date | '',
    createdAtBefore:Date|''
}



export const filterInitValues: TestAttemptsFilterFormType = {
    test: '',
    user: '',
    createdAtAfter: '',
    createdAtBefore:''
}

const formToFilterArgs = (values: TestAttemptsFilterFormType): Partial<TestAttemptFilterParams> => {
    const filterParams: Partial<TestAttemptFilterParams> = {}
    if (values.test)
        filterParams.test = parseInt(values.test)
    if (values.user)
        filterParams.user = values.user
    if (values.createdAtAfter)
        filterParams.createdAtAfter = dateFormat(values.createdAtAfter, apiDateFormat)
        if (values.createdAtBefore)
        filterParams.createdAtBefore = dateFormat(values.createdAtBefore, apiDateFormat)
    return filterParams
}

const AttemptsToolbar: React.FC<EnhancedTableToolbarProps> = ({ changeFilter, api, action, tests }) => {
    const [showFilters, setShowFilters] = useState(false);
    const handleShowFilter = () => setShowFilters(!showFilters)
    // const isSm = useMediaQuery((theme: CremaTheme) => theme.breakpoints.down('sm'))

    const handleFormChange = (values: TestAttemptsFilterFormType) => {
        changeFilter(formToFilterArgs(values))
    }
    const validationSchema = yup.object({
        test: yup.number().optional(),
        user: yup.string().optional(),
        createdAtAfter: yup.date(),
        createdAtBefore: yup.date()
        .when('createdAtAfter', (createdAtAfter: any, schema: any) => {
            try {
              if (
                yup.date().isValidSync(createdAtAfter)
              ) return schema.min(createdAtAfter)
            } finally {
              return schema
            }
          }),

    })
    return (
        <PaginatedToolbar action={action} title="Test Attempts" handleShowFilter={handleShowFilter} showFilters={showFilters}>
            <Formik
                initialValues={filterInitValues}
                validationSchema={validationSchema}
                onSubmit={(values, actions) => handleFormChange(values)}
            >
                {({ values, setFieldValue, isValid, errors, resetForm }) => {
                    return (
                        <Form noValidate autoComplete="off">
                            <Grid container direction={"row"} spacing={6} >

                                <Grid item container md={4} xs={12} spacing={2}>
                                    <Grid item style={{ flexGrow: 1 }}>
                                        <AppTextField
                                            name='test'
                                            style={{ width: '100%' }}
                                            size="small"
                                            label="Test Name"
                                            margin="none"
                                            select
                                        >
                                            <MenuItem value=''>Select</MenuItem>

                                            {tests.map((e,index) =>
                                                <MenuItem key={index} value={e.id}>{e.name}</MenuItem>
                                            )}
                                        </AppTextField>
                                    </Grid>
                                </Grid>
                                {/* <Grid item container md={4} xs={12} spacing={2}>
                                    <Grid item style={{ flexGrow: 1 }}>
                                        <AppTextField
                                            name='user'
                                            style={{ width: '100%' }}
                                            size="small"
                                            label="User"
                                            margin="none"
                                            select
                                        >
                                            <MenuItem value=''>Select</MenuItem>

                                            {users.map((e) =>
                                                <MenuItem value={e.id}>{e.email}</MenuItem>
                                            )}
                                        </AppTextField>
                                    </Grid>
                                </Grid> */}
                                <Grid item container md={4} xs={12} spacing={2}>
                                    <Grid item xs={6}  style={{ flexGrow: 1 }}>
                                        <FormikDateField
                                            size='small'
                                            name='createdAtAfter'
                                            inputFormat={defaultDateFormat}
                                            textFieldProps={{
                                                placeholder: defaultDateFormat
                                            }}
                                            label="From"
                                            shouldDisableDate={(
                                                day: Date) =>
                                                values.createdAtBefore !== '' && day && day.toDateString() !== values.createdAtBefore.toDateString() ?
                                                  day >= values.createdAtBefore : false
                                              }
                                            onChangeDate={(date)=>setFieldValue('createdAtAfter',date ?? '')}
                                        />
                                    </Grid>
                                    <Grid xs={6} item style={{ flexGrow: 1 }}>
                                        <FormikDateField
                                            size='small'
                                            name='createdAtBefore'
                                            inputFormat={defaultDateFormat}
                                            textFieldProps={{
                                                placeholder: defaultDateFormat
                                            }}
                                            label="To"
                                            shouldDisableDate={(
                                                day: Date) =>
                                                values.createdAtAfter !== '' && day && day.toDateString() !== values.createdAtAfter.toDateString() ?
                                                  day <= values.createdAtAfter : false
                                              }
                                            onChangeDate={(date)=>setFieldValue('createdAtBefore',date ?? '')}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item container spacing={2} md={4} xs={12}>
                                    <Grid item>
                                        <Button sx={{width:'120px',height:'36px',boxShadow:'none'}} variant="contained" size="small" color="primary" type="submit">
                                            {"Apply"}
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <IconButton onClick={() => resetForm()} size="small">
                                            <RefreshIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Form>
                    )
                }}
            </Formik>
        </PaginatedToolbar>
    );
};

export default React.memo(AttemptsToolbar)