import { FormHelperText, IconButton, InputBase, MenuItem, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { Fragment, RefObject, useEffect, useRef, useState } from 'react'
import VerifiedIcon from '@mui/icons-material/Verified';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import InputBaseStyles from 'styles/accordion.module.scss'
import clsx from 'clsx';
import { useField } from 'formik';
import { AutoFixHigh, Backspace, Close } from '@mui/icons-material';
import { ChoiceFormType } from 'containers/Authorized/Dashboard/tests/AddQuestionForm';
type InputBaseTypeProps = {
    value?: string | number,
    bgColor?: string,
    placeholder?: string,
    label?: string,
    errorText?: string,
    onChange: (val: string) => void,
    choices: ChoiceFormType[],
    questionArray: TemplateType[],
    setQuestionArray: (val: TemplateType[]) => void,
    handleSave?: () => void

}

export type TemplateType = string | {
    index: number,
    value: string
}

const FillTheBlankField: React.FC<InputBaseTypeProps> = ({ value, bgColor, label, errorText, onChange, choices, questionArray, setQuestionArray, handleSave }) => {
    return (
        <Box>
            {label && <Typography mb={1} ml={1} fontWeight={500} fontSize={'12px'}>{label}</Typography>}
            <Box
                display='flex'
                className={clsx(InputBaseStyles.inputBaseCustom)}
                sx={{ padding: '10px', gap: '10px', borderRadius: '8px', backgroundColor: `${bgColor}`, border: errorText !== undefined ? "solid 1px red" : "" }}
            >

                <Box
                    sx={{ flex: 1 }}

                    onInput={() => { }}
                >
                    {
                        questionArray.map((item, index) => {
                            return (
                                <Fragment key={index} >
                                    {
                                        typeof item === 'string' ? (
                                            <span
                                                style={{ display: "inline-flex", alignItems: 'center' }}>
                                                <TextField
                                                    sx={{ m: 2,width:300}}
                                                    multiline
                                                    onChange={(event) => {
                                                        setQuestionArray([...questionArray].map((e, i) => (index === i && typeof e === 'string' ? event.target.value : e)))
                                                    }}
                                                    size='small' value={item} />

                                            </span>
                                        ) : (
                                            <span style={{ display: "inline-flex" }}>
                                                <TextField
                                                    onChange={
                                                        (event) => {
                                                            setQuestionArray([...questionArray].map((e, i) => (index === i && typeof e !== 'string' ? { ...e, value: event.target.value } : e)))
                                                        }
                                                    } size='small' sx={{ display: 'inline-flex', ml: 2, mr: 2, p: 0 }} value={item.value} select>
                                                    <MenuItem value={"blank"}>Choose </MenuItem>
                                                    {choices.map((item, i) => <MenuItem key={index} value={item.choice}>{item.choice} </MenuItem>)}
                                                </TextField>

                                            </span>
                                        )
                                    }

                                </Fragment>
                            )

                        })
                    }
                    {questionArray.length  > 1 && <IconButton  onClick={() => { setQuestionArray([...questionArray].slice(0, -1))}}>
                        <Backspace />
                    </IconButton>}

                </Box>

                {/* <InputBase
                    multiline={!isChoice}
                    error={errorText !== undefined}
                    placeholder={placeholder}
                    fullWidth
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                /> */}
                <IconButton onClick={() => {
                    const tempArr = [...questionArray].filter(e => typeof e !== 'string')
                    setQuestionArray([...questionArray, { index: tempArr.length + 1, value: 'blank' }, ""])
                }} size='small' style={{ padding: 0 }}>
                    <AutoFixHigh />
                </IconButton>
                {handleSave && <IconButton disabled={false} onClick={() => {
                    handleSave()
                }} size='small' style={{ padding: 0 }}>
                    <SaveIcon />
                </IconButton>}
            </Box>
            {errorText && <FormHelperText sx={{
                color: (theme) => theme.palette.error.main
            }} id="outlined-input">{errorText}</FormHelperText>}
        </Box>
    )
}

export default FillTheBlankField