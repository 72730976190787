import React from "react";
import clsx from "clsx";
import AppContentView from "@cremaMods/core/AppContentView";
import AppFixedFooter from "../../../../@crema/core/AppLayout/HorDefault/AppFixedFooter";
import AppHeader from "../../../../@crema/core/AppLayout/HorDefault/AppHeader";
import AppSidebar from "../../../../@crema/core/AppLayout/HorDefault/AppSidebar";
import { useLayoutContext } from "../../../../@crema/utility/AppContextProvider/LayoutContextProvider";
import AppThemeSetting from "../../../../@crema/core/AppThemeSetting";
import HorDefaultWrapper from "../../../../@crema/core/AppLayout/HorDefault/HorDefaultWrapper";
import MainContent from "../../../../@crema/core/AppLayout/HorDefault/MainContent";
import { LayoutType } from "@cremaMods/shared/constants/AppEnums";
import HorDefaultContainer from "../../../../@crema/core/AppLayout/HorDefault/HorDefaultContainer";

const HorDefault:  React.FC<React.PropsWithChildren> = ({children}) => {
  const { footer, layoutType, footerType } = useLayoutContext();

  return (
    <HorDefaultContainer
      className={clsx({
        boxedLayout: layoutType === LayoutType.BOXED,
        framedLayout: layoutType === LayoutType.FRAMED,
      })}
    >
      <HorDefaultWrapper
        className={clsx("horDefaultWrapper", {
          appMainFooter: footer && footerType === "fluid",
          appMainFixedFooter: footer && footerType === "fixed",
        })}
      >
        <AppSidebar />

        <MainContent>
          <AppHeader />
          <AppContentView>
            {children}
          </AppContentView>
          <AppFixedFooter />
        </MainContent>
        <AppThemeSetting />
      </HorDefaultWrapper>
    </HorDefaultContainer>
  );
};

export default HorDefault;
