import { Button, Dialog, DialogActions, DialogTitle, Typography } from '@mui/material'
import React from 'react'

type SetAnswerWarningDialogProps = {
    open:boolean,
    handleFlow:(setAnswer:boolean)=>void
}

const SetAnswerWarningDialog:React.FC<SetAnswerWarningDialogProps> = ({open,handleFlow}) => {
  return (
    <Dialog open={open} >
        <DialogTitle>{`Are you sure you want to continue?`}</DialogTitle>
        {/* <DialogContent>
          <DialogContentText>
            Warning: You cannot reverse this action!
          </DialogContentText>
        </DialogContent> */}
        <DialogActions style={{padding:'0 12px 12px 0'}}>
          <Button size='small' variant="outlined" color="primary" onClick={() => handleFlow(false)}>
            <Typography variant='body2'>Cancel</Typography>
          </Button>
          <Button  size='small' color="secondary" variant="outlined" onClick={() => handleFlow(true)} autoFocus>
            <Typography variant='body2'>Confirm</Typography>
          </Button>
        </DialogActions>
      </Dialog>
  )
}

export default SetAnswerWarningDialog