import { createAsyncThunk, createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit"
import { IApiState } from "api/api"
import { signInWithEmailAndPassword, signOut } from "firebase/auth"
import { auth } from "services/firebase"

import { AppState } from "store/store"
import authUtil from "util/authUtil"

enum ApiStatusEnum {
    IDLE = 'idle',
    LOADING = 'loading',
    SUCCEEDED = 'succeeded',
    FAILED = 'failed'
}


type InitialStateType = {
    api: IApiState,
    jwt?: string
    user?: LoginUserType,
    
}


const getInitialState = (): InitialStateType => {

    return {
        api: {
            status: ApiStatusEnum.IDLE,
            error: null
        },
        jwt: authUtil.readJwt(),
    }
}


export const loginThunk = createAsyncThunk('auth/login', async (payload: ILoginRequest, { rejectWithValue }) => {

    try {
        const response = await signInWithEmailAndPassword(auth, payload.email, payload.password)
        const jwt = await response.user.getIdToken()
        authUtil.writeJwt(jwt)
        const {displayName, email, emailVerified, isAnonymous, phoneNumber, photoURL, uid} = response.user
        return {user:{displayName,email,emailVerified,isAnonymous,phoneNumber,photoURL,uid},jwt:jwt}

    } catch (error: any) {
        return rejectWithValue(error)
    }
})

export const logoutThunk = createAsyncThunk('auth/logout', async (_: undefined, { rejectWithValue }) => {
    try {
        await signOut(auth)
        authUtil.writeJwt(undefined)
        return true
    } catch (err: any) {
        authUtil.writeJwt(undefined)
        return rejectWithValue(err)
    }

})





const authSlice = createSlice({

    name: 'auth',
    initialState: getInitialState(),
    reducers: {
        resetState(state) {
            state.api = { status: ApiStatusEnum.IDLE, error: null }
        },
        setJwtToken(state, action: PayloadAction<string | undefined>) {
            state.jwt = action.payload
        },
        setCurrentUser(state,action:PayloadAction<LoginUserType>){
            state.user = action.payload
        }
    },
    extraReducers: (builder) => {
       
        builder.addCase(loginThunk.fulfilled, (state, action) => {
            state.api = {
                status: ApiStatusEnum.SUCCEEDED,
                error: null
            }
            state.jwt = action.payload.jwt
            state.user=action.payload.user
        })
        builder.addMatcher(isAnyOf(
            logoutThunk.pending, 
            loginThunk.rejected,
            loginThunk.pending,), (state) => {
            state.api = {
                status: ApiStatusEnum.SUCCEEDED,
                error: null
            }
        })
        builder.addMatcher(isAnyOf(logoutThunk.fulfilled, logoutThunk.rejected), (state) => {
            state.api = {
                status: ApiStatusEnum.SUCCEEDED,
                error: null
            }
            state.jwt = undefined
        })
    }
})


export default authSlice.reducer


export const {
    resetState: resetAuthState,
    setJwtToken,
    setCurrentUser
} = authSlice.actions
export const selectUserApiStatus = (state: AppState): IApiState => state.auth.api

export const selectIsAuthenticated = (state: AppState): boolean => state.auth.jwt !== undefined

export const selectAuthUser = (state: AppState): LoginUserType | undefined => state.auth.user

