
import AttemptsTable from './AttemptsTable'

const ListAttempts = () => {
 
  return (
    <AttemptsTable/>
  )
}

export default ListAttempts