import { AppLayout } from '@crema';
import {  useMemo } from 'react'
import { Toaster } from 'react-hot-toast';
import { useRoutes } from 'react-router';
import generateRoutes from 'routes';
import authorizedRoute from 'routes/authorizedRoute';
import unAuthorizedRoute from 'routes/unAuthorizedRoute';
import { useAppSelector } from 'store/hooks';
import { selectIsAuthenticated } from 'store/reducers/auth.reducer';

const Root = () => {
    const isAuthenticated = useAppSelector(state => selectIsAuthenticated(state))
    const memoGenerateRoutes = useMemo(() => generateRoutes({
        isAuthenticated: isAuthenticated,
        authorizedRoute: authorizedRoute,
        unAuthorizedRoute: unAuthorizedRoute
    }), [isAuthenticated])
    const element = useRoutes(memoGenerateRoutes)

    return (
        <AppLayout isAuthenticated={isAuthenticated}>
            { element}
            <Toaster/>
        </AppLayout>
    )

}
export default Root;