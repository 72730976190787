import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { ApiStatusEnum, IApiState } from "api/api";
import testAttemptApi from "api/testAttemptApi";
import { AppState } from "store/store";

const listAnswerAdapter = createEntityAdapter<TestAttemptListAnswerType>()



type InitialStateType = {
   api:IApiState
}

const addOnInitialState:InitialStateType = {
    api:{
        status:ApiStatusEnum.IDLE,
        error:null
    }
}

export const answerListThunk = createAsyncThunk(
    'answer/list',
    async(payload:number,{rejectWithValue,getState})=>{
        try {
           const response = testAttemptApi.listAnswer(payload)
        return (await response).data
        } catch (error:any) {
            return rejectWithValue(error.response.data)
        }
    }
)



export const listAnswerSlice = createSlice({
    name:'listAnswer',
    initialState:listAnswerAdapter.getInitialState(addOnInitialState),
    reducers:{
        resetState(state){
            state.api = {
                status:ApiStatusEnum.IDLE
            }
            listAnswerAdapter.removeAll(state)
        },
    },
    extraReducers:builder => {
        builder.addCase(answerListThunk.fulfilled,(state,action)=>{
            state.api = {
                status:ApiStatusEnum.SUCCEEDED,
                error:null
            }
            listAnswerAdapter.removeAll(state)
            listAnswerAdapter.addMany(state,action.payload)
        })

        builder.addCase(answerListThunk.pending,(state,action)=>{
            state.api = {
                status:ApiStatusEnum.LOADING,
                error:null
            }
        })

        builder.addCase(answerListThunk.rejected,(state,action)=>{
            state.api = {
                status:ApiStatusEnum.FAILED,
                error:action.payload
            }
        })
    }
})

export default listAnswerSlice.reducer

export const 
{
    resetState:resetListAnswerState

} = listAnswerSlice.actions

export const 
{
   selectAll:selectAllAnswers
} = listAnswerAdapter.getSelectors((state:AppState)=>state.listAnswer)

export const selectListAnswerApi = (state:AppState)=>state.listAnswer.api

