
import { Box, Grid, Typography, useTheme } from '@mui/material'
import React from 'react'

type BasicTableToolbarProps = {
    title: string,
    action?: React.ReactNode
}


const BasicTableToolbar: React.FC<BasicTableToolbarProps> = ({ title,action }) => {
    // const theme = useTheme<CremaTheme>();
    return (
        <Box p={4} >
            <Grid container spacing={4}> 
            <Grid container item xs={12} direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item>
                    <Typography variant="h4" id="tableTitle" component="div">
                        {title}
                    </Typography>
                    </Grid>
                    <Grid item style={{display: 'inline-flex'}}>
                        {action}
                    </Grid>
                </Grid>
            </Grid> 
            </Box>
    )
}

export default BasicTableToolbar