import Authorized from "containers/Authorized";
import { DetailedRouteConfig } from "routes/routes.typing";
import dashboardRoute from "./dashboardRoute";

const routes : DetailedRouteConfig = {
    path: '/',
    fallbackPath: '/login',
    element: <Authorized/>,
    children: [
        dashboardRoute
    ]
}

export default routes;