import { FaTasks, FaUsers } from "react-icons/fa";
import { GrConfigure, GrDocumentConfig } from "react-icons/gr";
import {MdQuiz,
} from "react-icons/md";
import { ReactNode } from "react";

export interface RouterConfigData {
  id: string;
  title: string;
  messageId: string;
  icon?: string | ReactNode;
  type: "item" | "group" | "collapse" | "divider";
  children?: RouterConfigData[];
  // permittedRole?: RoutePermittedRole;
  color?: string;
  url?: string;
  // exact?: boolean;
  count?: number;
}

const routesConfig: RouterConfigData[] = [
  {
    id: "manage",
    title: "Manage",
    messageId: "common.manage",
    type: "group",
    children: [
      // {
      //   id: "dashboard",
      //   title: "Dashboard",
      //   messageId: "sidebar.manage.dashboard",
      //   type: "item",
      //   icon: <RiDashboardFill />,
      //   url: "/dashboard/home",      },
      {
        id: "tests",
        title: "Tests",
        messageId: "sidebar.manage.tests",
        type: "item",
        url: "/dashboard/tests",
        icon: <MdQuiz/>,
        
      },
      {
        id: "test_attemts",
        title: "Test Attempts",
        messageId: "sidebar.manage.test.attempts",
        type: "item",
        icon: <FaTasks/>,
        url: "/dashboard/attempts",
      },
      {
        id: "users",
        title: "Users",
        messageId: "sidebar.manage.users",
        type: "item",
        icon: <FaUsers />,
        url: "/dashboard/users",
      },

      {
        id: "config",
        title: "Config",
        messageId: "sidebar.manage.config",
        type: "item",
        icon: <GrConfigure />,
        url: "/dashboard/config",
      },
    ],
  },
];
export default routesConfig;
