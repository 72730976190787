import * as React from 'react';
import {
    Formik,
    FormikHelpers,
    Form,

} from 'formik';
import * as yup from 'yup'
import FormikTextField from '../../../components/FormikTextField';
import { Box, Button, Link, Paper, Typography } from '@mui/material';
import clsx from 'clsx';
import LoginStyles from 'styles/login.module.scss'
import { useAppDispatch } from 'store/hooks';
import { loginThunk } from 'store/reducers/auth.reducer';



const LoginForm = () => {
    const dispatch = useAppDispatch()
    const handleSubmit = (async (values: ILoginRequest, actions: FormikHelpers<ILoginRequest>) => {
        // signInWithEmailAndPassword(auth, values.email, values.password)
        //     .then((userCredential) => {
        //         const user = userCredential.user.email
        //     })
        //     .catch((error) => {
        //         const errorCode = error.code;
        //         const errorMessage = error.message;
        //         console.log(error.code)
        //     });
        actions.setSubmitting(true)
        dispatch(loginThunk(values))
        .finally(()=>{
            actions.setSubmitting(false)
        })

    })


    const initialValues: ILoginRequest = { email: '', password: '', };
    const validationSchema = yup.object({
        email: yup.string().required('Email is required'),
        password: yup.string().required('Password is required'),

    })

    return (
        <Box
            component="main"
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height:'100%'

            }}
        >
            <Box component={Paper} p={5}>
                <Box className={clsx(LoginStyles.formContainer)}>
                    <Typography variant='h1'>Login</Typography>
                    <Formik
                        initialValues={initialValues}
                        validateOnChange={true}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}

                    >
                        {actions => (
                            <Form className={clsx(LoginStyles.loginForm)}>
                                <FormikTextField
                                    name='email'
                                    placeholder='Email'
                                    fullWidth
                                    className={clsx(LoginStyles.loginFormField)}
                                />

                                <FormikTextField
                                    type='password'
                                    name='password'
                                    placeholder='Password'
                                    fullWidth
                                    className={clsx(LoginStyles.loginFormField)}
                                />

                                <Button
                                color='primary'
                                sx={{
                                    height:'50px'
                                }}
                                    disabled={actions.isSubmitting}
                                    type='submit'
                                    variant='contained'
                                    fullWidth
                                >Login</Button>
                                {/* <Box className={clsx(LoginStyles.loginFormLink)}>

                                    <Link type='button' component="button">Forgot password?</Link>
                                    <Link type='button' component="button">Create an account?</Link>
                                </Box> */}
                            </Form>
                        )}
                    </Formik>
                </Box>
            </Box>
        </Box>

    )
}

export default LoginForm