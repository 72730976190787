
import { AppScrollbar } from '@crema';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { TableCellProps } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { ApiStatusEnum, IApiState } from 'api/api';
import React from 'react';
import FullWidthTableCell from '../FullwidthTableCell';
import { PaginatedOrder } from './types';


type PaginatedTableColumnType =  {
  value: string | number | React.ReactElement,
  tableCell?: TableCellProps
}

export type PaginatedTableRowType<T extends {}> = {
  id: string | number,
  values: {[key in keyof T]: PaginatedTableColumnType} 
}  

type PaginatedTableProps<T extends {}> = {
  toolbar?: React.ReactElement
  tableHead?: React.ReactElement
  api?: IApiState
  action?: React.ReactElement
  rows: PaginatedTableRowType<T>[],
  pagination: {
    count?: number
    currentPage: number,
    nextIconDisabled: boolean,
    handleChangePage: (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => void,
    rowsPerPage: number,
    rowsPerPageOptions?: number[]
    handleChangeRowsPerPage?: (event: React.ChangeEvent<HTMLInputElement>) => void
  }
}

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator<T extends {}>(
  order: PaginatedOrder,
  orderBy: keyof T,
): (a: T, b: T) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function PaginatedTable<T extends {}>(props: PaginatedTableProps<T>) {
  const {
    toolbar,
    tableHead,
    api,
    action,
    rows,
    pagination: {
      rowsPerPageOptions = [10, 25, 50],
      count = -1,
      rowsPerPage,
      currentPage,
      nextIconDisabled,
      handleChangePage,
      handleChangeRowsPerPage
    },
  } = props

  const getPagination = () => (
    <TablePagination
      rowsPerPageOptions={rowsPerPageOptions}
      component="div"
      count={count}
      rowsPerPage={rowsPerPage}
      page={currentPage}
      onPageChange={handleChangePage}
      onRowsPerPageChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeRowsPerPage && handleChangeRowsPerPage(e)}
      nextIconButtonProps={{ disabled: nextIconDisabled }}
    />
  )
  const buildFooter = () => (
    <Grid container direction="row" justifyContent="space-between" alignItems="center">
      <Grid item>
        {action}
      </Grid>
      <Grid item>
        {getPagination()}
      </Grid>

    </Grid>
  )
  return (
    <React.Fragment>
      {toolbar !== undefined && toolbar}
      <TableContainer>
        <AppScrollbar>
          <Table
            style={{
              minWidth: 750,
            }}
            aria-labelledby="tableTitle"
            size='small'
            aria-label="enhanced table"
          >
            {tableHead !== undefined && tableHead}
            <TableBody>
            {rows.map(row => (
            <TableRow key={row.id}>
              {Object.entries(row.values).map(([key, value], index) => (
                <FullWidthTableCell key={key} {...(value as PaginatedTableColumnType).tableCell}>
                  {(value as PaginatedTableColumnType).value}
                </FullWidthTableCell>
              ))}
            </TableRow>
          ))}
            </TableBody>
          </Table>
        </AppScrollbar>
      </TableContainer>
      {api && api.status === ApiStatusEnum.LOADING && <LinearProgress />}
      {action ? buildFooter() : getPagination()}
    </React.Fragment>
  );
};

export default PaginatedTable;