import React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import FilterListIcon from '@mui/icons-material/FilterList';

interface PaginatedToolbarProps {
    handleShowFilter?: () => void,
    showFilters?: boolean,
    title: string,
    action?: React.ReactNode
}



const PaginatedToolbar: React.FC<React.PropsWithChildren<PaginatedToolbarProps>> = ({ children, handleShowFilter, title, showFilters=false , action}) => {
    return (
        <Box m={3}>
            <Grid container spacing={4}>
                <Grid container item xs={12} direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item>
                    <Typography variant="h4" id="tableTitle" component="div">
                        {title}
                    </Typography>
                    </Grid>
                    <Grid item style={{display: 'inline-flex'}}>
                        {handleShowFilter && (
                        <Tooltip title="Filter list">
                            <IconButton aria-label="filter list" onClick={() => handleShowFilter()}>
                                <FilterListIcon />
                            </IconButton>
                        </Tooltip>
                        )}
                        {action}
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{...(!showFilters && {display: 'none'})}}>
                    {children}
                </Grid>
            </Grid>
        </Box>
    );
};


export default PaginatedToolbar;