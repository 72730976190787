import { apiInstance } from "api/client";

export const configAPi = {
    list: async () => {
        return apiInstance.get<SeruyConfigResponseType[]>('/dynamic-config/',{cvtResToCamel:true})
    },

    create:async (data:SeruyConfigResponseType) => {
        return apiInstance.post<SeruyConfigResponseType>('/dynamic-config/',data,{cvtResToCamel:true,cvtReqBodyToSnake:true})
    },
    delete:async (key:string) => {
        return apiInstance.delete<SeruyConfigResponseType[]>(`/dynamic-config/${key}`,{cvtResToCamel:true})
    },
}