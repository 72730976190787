import React from 'react'
import DialogWithClose from 'components/Dialog/DialogWithClose'
import TestForm from './TestForm'

interface Props {
    id?: number,
    open: boolean,
    handleClose: () => void,
}

const TestAddDialog: React.FC<Props> = ({ handleClose, id, open }) => {
    return (
        <DialogWithClose maxWidth={'md'}  title={id ? 'Edit Test Details' : "Add Test "} open={open} onClose={handleClose}>
                    <TestForm hanldeClose={handleClose} id={id} />
        </DialogWithClose>
    )
}

export default TestAddDialog