import { createAsyncThunk, createEntityAdapter, createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
import { ApiStatusEnum, IApiState } from "api/api";
import { configAPi } from "api/configApi";
import { AppState } from "store/store";

// type SettingType = {
//     navCollapsed: boolean;
//     initialPath: string | undefined;
//   }
const configAdapter = createEntityAdapter<SeruyConfigResponseType>({
    // sortComparer: (a, b) => a.position.toString().localeCompare(b.position.toString())
    selectId: obj => obj.configKey
})

type InitialStateType = {
    api: IApiState,
}

const addOnInitialState: InitialStateType = {
    api: {
        status: ApiStatusEnum.IDLE,
        error: null
    },
}

export const getConfigThunk = createAsyncThunk(
    'setting/getConfig',
    async (_: undefined, { rejectWithValue }) => {
        try {
            const response = await configAPi.list()
            return response.data
        } catch (error: any) {
            return rejectWithValue(error.response.data)
        }
    }
)

export const createConfigThunk = createAsyncThunk(
    'setting/createConfig',
    async (data: SeruyConfigResponseType, { rejectWithValue }) => {
        try {
            const response = await configAPi.create(data)
            return response.data
        } catch (error: any) {
            return rejectWithValue(error.response.data)
        }
    }
)

export const deleteConfigThunk = createAsyncThunk(
    'setting/deleteConfig',
    async (key: string, { rejectWithValue }) => {
        try {
            const response = await configAPi.delete(key)
            return key
        } catch (error: any) {
            return rejectWithValue(error.response.data)
        }
    }
)


const configSlice = createSlice({
    name: 'config',
    initialState: configAdapter.getInitialState(addOnInitialState),
    reducers: {
        resetState(state) {
            state.api = {
                status: ApiStatusEnum.IDLE
            }
            configAdapter.removeAll(state)
        }
    },
    extraReducers(builder) {
        builder.addCase(getConfigThunk.fulfilled, (state, action) => {
            state.api = {
                status: ApiStatusEnum.SUCCEEDED
            }
            configAdapter.upsertMany(state, action.payload)
        })
        builder.addCase(createConfigThunk.fulfilled, (state, action) => {
            state.api = {
                status: ApiStatusEnum.SUCCEEDED
            }
            configAdapter.upsertOne(state, action.payload)
        })
        builder.addCase(deleteConfigThunk.fulfilled, (state, action) => {
            state.api = {
                status: ApiStatusEnum.SUCCEEDED
            }
            configAdapter.removeOne(state, action.payload)
        })

        builder.addMatcher(isAnyOf(
            getConfigThunk.pending,
            createConfigThunk.pending,
            deleteConfigThunk.pending
        ), (state, action) => {
            state.api = {
                status: ApiStatusEnum.LOADING
            }
        })

        builder.addMatcher(isAnyOf(
            getConfigThunk.rejected,
            createConfigThunk.rejected,
            deleteConfigThunk.rejected
        ), (state, action) => {
            state.api = {
                status: ApiStatusEnum.FAILED,
                error: action.payload
            }
        })
    },
})


export default configSlice.reducer

export const
    {
        selectAll: selectAllConfig
    } = configAdapter.getSelectors((state: AppState) => state.config)