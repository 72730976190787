import { createAsyncThunk, createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApiStatusEnum, IApiState } from "api/api";
import userApi from "api/UserApi";
import { AppState } from "store/store";
import apiUtil from "util/api.util";

const userAdapter = createEntityAdapter<QAdminUserResponseType>({selectId:(obj) => obj.user.id})

type UserListArgsType = {
    filter: UserFilterParams,
    pagination: PaginationType
}

type InitialStateType = {
    api: IApiState,
    listArgs: UserListArgsType
}

const addOnInitialState: InitialStateType = {
    api: {
        status: ApiStatusEnum.IDLE,
        error: null
    },
    listArgs: {
        filter: {
            pageSize: 25
        },
        pagination: {}
    }
}

export const userListThunk = createAsyncThunk(
    'user/list',
    async (_: undefined, { rejectWithValue, getState }) => {
        const appState = getState() as AppState
        const args: UserListArgsType = appState.user.listArgs
        try {
            const response = args.pagination.next ?
                await userApi.getUserListWithUrl(args.pagination.next) :
                await userApi.getUserProfileListByAdmin(args.filter)
            return {
                
                data: response.data,
                pagination: { next: apiUtil.extractAxiosHeaderLink(response, 'next') },
                reset: args.pagination.next ? false : true
            }
        } catch (error: any) {
            return rejectWithValue(error.response.data)
        }
    }
)

export const userSyncThunk = createAsyncThunk(
    'user/sync/firebase',
    async (id: string, { rejectWithValue, getState }) => {
        const appState = getState() as AppState
        const user = selectUserById(appState, id)
        try {
            const response = await userApi.firebaseUserSync(id)
            if (user)
                return { ...user, user: response.data }
        } catch (error: any) {
            return rejectWithValue(error.response.data)
        }
    }
)

export const userSlice = createSlice({
    name: 'user',
    initialState: userAdapter.getInitialState(addOnInitialState),
    reducers: {
        resetState(state) {
            state.api = {
                status: ApiStatusEnum.IDLE
            }
            userAdapter.removeAll(state)
        },
        setFilters(state, action: PayloadAction<Partial<UserFilterParams>>) {
            state.listArgs = {
                filter: {
                    pageSize: state.listArgs.filter.pageSize,
                    ...action.payload
                },
                pagination: {}
            }
        },

    },
    extraReducers: builder => {
        builder.addCase(userListThunk.fulfilled, (state, action) => {
            state.api = {
                status: ApiStatusEnum.SUCCEEDED,
                error: null
            }
            state.listArgs.pagination = action.payload.pagination
            if (action.payload.reset)
                userAdapter.removeAll(state)
            userAdapter.upsertMany(state, action.payload.data)
        })

        builder.addCase(userSyncThunk.fulfilled, (state, action) => {
            state.api = {
                status: ApiStatusEnum.SUCCEEDED,
                error: null
            }
            if (action.payload)
                userAdapter.upsertOne(state, action.payload)
        })

        builder.addCase(userListThunk.pending, (state, action) => {
            state.api = {
                status: ApiStatusEnum.LOADING,
                error: null
            }
        })

        builder.addCase(userListThunk.rejected, (state, action) => {
            state.api = {
                status: ApiStatusEnum.FAILED,
                error: action.payload
            }
        })
    }
})

export default userSlice.reducer

export const
    {
        resetState: resetUserState,
        setFilters: setUserFilters

    } = userSlice.actions

export const
    {
        selectAll: selectAllUser,
        selectById: selectUserById
    } = userAdapter.getSelectors((state: AppState) => state.user)

export const selectUserApi = (state: AppState): IApiState => state.user.api

export const selectUserPagination = (state: AppState) => state.user.listArgs.pagination

export const selectUserFilterParams = (state: AppState) => state.user.listArgs.filter