import { FormHelperText, IconButton, InputBase, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import VerifiedIcon from '@mui/icons-material/Verified';
import InputBaseStyles from 'styles/accordion.module.scss'
import clsx from 'clsx';
import CloseIcon from '@mui/icons-material/Close';
type InputBaseTypeProps = {
    name: string
    isAnswer?: boolean,
    value?: string | number,
    setIsAnswer?: () => void,
    isChoice?: boolean,
    bgColor?: string,
    placeholder?: string,
    label?: string,
    acceptNull?: boolean,
    errorText?: string,
    onChange: (val: string) => void,
    onClose?:() => void

}

const FormikCustomField: React.FC<InputBaseTypeProps> = ({ isAnswer, value, setIsAnswer, isChoice, bgColor, placeholder, label, acceptNull, name, errorText, onChange,onClose }) => {



    const [inputValue, setInputValue] = useState(value ?? '')

    return (
        <Box>
            {label && <Typography mb={1} ml={1} fontWeight={500} fontSize={'12px'}>{label}</Typography>}
            <Box
                display='flex'
                alignItems={isChoice ? 'center' : 'flex-start'}
                className={clsx(InputBaseStyles.inputBaseCustom)}
                sx={{ padding: '10px', gap: '10px', borderRadius: '8px', backgroundColor: `${bgColor}`, border: errorText !== undefined ? "solid 1px red" : "" }}
            >
                {isChoice && setIsAnswer && <IconButton onClick={() => setIsAnswer()} size='small' style={{ padding: 0 }}>
                    <VerifiedIcon color={isAnswer ? 'success' : 'disabled'} />
                </IconButton>}
                <InputBase
                    multiline={!isChoice}
                    error={errorText !== undefined}
                    placeholder={placeholder}
                    fullWidth
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                />
                {isChoice && onClose &&  <IconButton sx={{p:0}} onClick={() => onClose()}>
                    <CloseIcon />
                </IconButton>}
            </Box>
            {errorText && <FormHelperText sx={{
                color: (theme) => theme.palette.error.main
            }} id="outlined-input">{errorText}</FormHelperText>}
        </Box>
    )
}

export default FormikCustomField